import React, { useState } from "react";
import "./styles.css";
import SecondaryButton from "./SecondaryButton";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useSpring, animated } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import { setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import PrimaryButton from "./PrimaryButton";
import TextInput from "./TextInput";
import { setUser } from "../redux/OnBoardingSlice";
import ApiService, { ApiResponse } from "../api/ApiServices";
import { isValidPassword } from "../utils/utils";
import moment from "moment";
const StepEight = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);
  const [inputValue, setInputValue] = useState<Record<string, string>>({});
  const [secure, setSecure] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState<boolean>(false);
  const handleInputChange = (e: string, type: string) => {
    if (type === 'password') {
      setPasswordValidation(!isValidPassword(e))
    }
    setInputValue({
      ...inputValue,
      [type]: e,
    });
  };

  const handleCreateUser = async () => {
    try {
      const data = {
        role: "user",
        email: user?.email,
        name: inputValue.username,
        password: inputValue.password,
        createdAt: moment().format('YYYY-MM-DD'),
        dob: user?.dob && moment().month(user?.dob?.month).format('MM') + '/' + user?.dob?.year,
        gurdians: user?.gurdians,
        otherInputValues: {
          "What would you love from U4U?": {otherInput: user?.other}
        },
        questions: {
          "What's your dream home?": user?.step1,
          "What party excites you the most?": user?.step2,
          "You journey through life in:": user?.step3,
          "What would you love from U4U?": user?.step4 && [...user?.step4],
          "What grade are you in?": user?.step5,
          "Where are you from?": { state: user?.state, city: user?.city, school: user?.school },
          "Tell us the best way to stay in touch with you!": {
            email: user?.email,
            instagramId: user?.instagramId,
          },
          "Time to get your keys!": {
            username: inputValue.username,
            password: inputValue.password,
          },
        },
      };

      const response = await ApiService.createUser(data);
      if (response) {
        navigate(AppPaths.EMAILVERIFICARION);
      }
    } catch (error: any) {
      if (error?.message) {
        alert(error.message);
      } else {
        alert("Unable to create user please try again laters");
      }
    }
  };

  const onContinue = () => {
    dispatch(
      setUser({
        ...user,
        username: inputValue.username,
        password: inputValue.password,
      })
    );
    handleCreateUser();
  };
  return (
    <div className="flex flex-1 flex-col">
      <div className="mx-5  flex flex-col flex-1 ">
        <div className="flex flex-row justify-center py-2 mt-5">
          <img
            alt=""
            src={require("../assets/ob8key.png")}
            className="ob8key"
          />
        </div>
        <div className="TextInputContainer">
          <span className="">
            <span className="flex flex-row items-center">
              <div className="input-text-14-bold font-bold">
                Create Username
              </div>
            </span>
            <TextInput
              placeholder="You'll use this to login"
              value={inputValue.username}
              onChange={(val) =>
                handleInputChange(val.target.value, "username")
              }
              containerStyles="px-3 py-3"
              textInputStyles={{
                flex: 1,
                display: "flex",
              }}
            />
          </span>
          <span className="">
            <span className="flex flex-row items-center mt-4">
              <div className="input-text-14-bold font-bold">
                Create Password (required)
              </div>
            </span>
            <TextInput
              placeholder="Enter Password"
              value={inputValue.password}
              textStyle={"flex flex-1"}
              onChange={(val) =>
                handleInputChange(val.target.value, "password")
              }
              secure={secure}
              containerStyles="px-3 py-3"
              rightIcon={
                <img
                  alt=""
                  className="Img-24"
                  src={!secure ? require("../assets/eye_open.png") : require("../assets/eye_close.png")}
                  onClick={() => {
                    setSecure(!secure);
                  }}
                />
              }
            />
            {passwordValidation && <span className="mt-4" style={{ color: 'red'}}>Password is not valid!</span>}
          </span>

          <div className="pt-5">
            <div className="inter-regular-14 ">Password must include:</div>
            <ul style={{ listStyleType: "disc" }} className="mx-4">
              <li className="inter-regular-14  pt-1">
                one special character *!#$%
              </li>
              <li className="inter-regular-14  pt-1">one number</li>
              <li className="inter-regular-14  pt-1">one capital letter</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="pt-6 mb-1">
        <PrimaryButton
          text="DONE!"
          onClick={onContinue}
          containerStyles="py-3 px-8 mx-5"
          disable={passwordValidation || !inputValue.password || !inputValue.username}
        />
      </div>
      <span className="flex flex-row  justify-center items-center">
        <div className="term-text">Privacy Terms & Conditions</div>
      </span>
    </div>
  );
};

export default StepEight;
