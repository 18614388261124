import { useLocation, useNavigate } from "react-router-dom";
import UserFeedCard from "../../components/dashboard/UserFeedCard";
import { AppPaths } from "../../utils/AppPaths";
import HomeDancePicFragment from "../../components/dashboard/HomeDanceFragmentPic";
import ApiService from "../../api/ApiServices";
import { useEffect, useState } from "react";
import LoadImage from '../../assets/loader.gif';

const DanceChallange = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [load, setLoad] = useState<boolean>(false)
  const [quizData, setQuizData] = useState<any>(null)
  const getQuizData = async () => {
    setLoad(true);
    const response = await ApiService.getQuizById(id);
    setQuizData(response?.data);
    if (response?.data?.challenges?.metadata?.isUrl) {
      window.open(response?.data?.challenges?.metadata?.url, '_blank')
    }
    setLoad(false)
  }

  useEffect(() => {
    if (id) {
      getQuizData()
    }
  }, [id])
  return (
    <>
   {load ? <div className="flex justify-center items-center h-screen mb-5">
      <img width="100px" className="center" src={LoadImage} alt="loader" />
    </div> : <div className="flex flex-col relative pb-20 mx-1 pt-3">
      <div className="flex flex-1 flex-row mx-3 items-center">
        <div>
          <img
            onClick={() => {
              navigation(AppPaths.MYJOURNEY);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2 cursor-pointer"
            src={require("../../assets/arrow-left.png")}
          />
        </div>
        <div className="btn-onb2-text">{quizData?.challenges?.title}</div>
      </div>
      <div className="py-5 mx-auto">
        <HomeDancePicFragment
          img={quizData?.challenges?.url}
          imgStyle={"challange-frag-img"}
          contStyle={`overflow-hidden challange-frag-img-cont`}
        />
      </div>
      <div className="inter-20-bold mx-auto">{quizData?.challenges?.houseName} House Responses</div>
      <div className="flex flex-1 flex-row items-center mx-3">
        <span className="bg-black h-0.5 flex flex-1"></span>
        <span className="flex flex-row items-center mx-2">
          <img
            src={quizData?.challenges?.image ? quizData?.challenges?.image : require("../../assets/placeholder_gem.png")}
            alt=""
            className="header-jem-sm mr-1"
          />
          <div className="inter-14-bold ">{quizData?.challenges?.points}&nbsp;pts</div>
        </span>
        <span className="bg-black h-0.5 flex flex-1"></span>
      </div>
      <div className=" flex flex-1 flex-col">
        {quizData?.attemptChallenges?.map((item: any) => {
          return <UserFeedCard type="text" data={item} />
        })}
      </div>
    </div>}
    </>
  );
};

export default DanceChallange;
