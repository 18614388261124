import React from "react";
import "./styles.css";
import { CircleLoader, RingLoader, BeatLoader } from "react-spinners";

const PrimaryButton = ({
  text,
  onClick,
  icon = null,
  containerStyles = "",
  textStyle = "",
  disable = false,
  loading = false,
  disabled = false,
}: {
  text: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  icon?: any;
  containerStyles?: string;
  textStyle?: string;
  disable?: boolean;
  loading?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`relative primary-btn flex  ${containerStyles} ${
        disable ? "disable-primary cursor-not-allowed" : "cursor-pointer"
      } ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
      onClick={disable || disabled ? undefined : onClick}
    >
      {loading ? (
        <BeatLoader
          color={"black"}
          loading={loading}
          className="h-6 flex justify-center items-center"
          size={12}
        />
      ) : (
        <>
          {icon && <div className="absolute left-2">{icon}</div>}
          <div
            className={`${textStyle ? textStyle : "primary-btn-text"} ${
              textStyle && "text-white"
            }`}
          >
            {text}
          </div>
        </>
      )}
    </div>
  );
};

export default PrimaryButton;
