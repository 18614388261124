import moment from "moment";
import { baseURL } from "../../api/Api";
import { AppPaths } from "../../utils/AppPaths";
import { useNavigate } from "react-router-dom";
import Avatar from "react-avatar";

const UserCardHeader = ({
  imageUrl = null,
  header = "",
  subHeader = "",
  data = null,
}: {
  imageUrl?: string | null;
  header?: string;
  subHeader?: string;
  data?: any;
}) => {
  const navigate = useNavigate();
  const handleChallenge = (type: string, id: string) => {
    if (type === 'text') {
      navigate(`${AppPaths.DANCE_CHALLENGE}?id=${id}`)
    } else if (type === 'image' || type === 'video') {
      navigate(`${AppPaths.HALLOWEEN_CHALLENGE}?id=${id}&type=${type}`)
    } else {
      navigate(`${AppPaths.FALLFUN_QUIZ_CHALLENGE}?id=${id}`)
    }
  }
  return (
    <div className="flex flex-1 flex-row py-2">
      <div className="mr-2">
        {data?.userId?.profileUrl !== "" ? <img
          alt=""
          src={data?.userId?.profileUrl !== "" ? baseURL + data?.userId?.profileUrl : "https://picsum.photos/200/300"}
          className="user-cardheader"
        /> : <Avatar name={data?.userId?.name} size="21.27" round color={"black"} fgColor={"white"} />}
      </div>
      <div>
        <div className="inter-12-bold">{data?.userId?.name}</div>
        <div className="inter-regular-12">{moment(data?.createdAt).format('MMM DD, YYYY')} - <span className="underline cursor-pointer" onClick={() => handleChallenge(data?.challengeId?.type, data?.challengeId?._id)}>{data?.challengeId?.title}</span></div>
      </div>
    </div>
  );
};

export default UserCardHeader;
