import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../types";

type UserDetails =
  | (Partial<User> & {
      step1?: string;
      step2?: string;
      step3?: string;
      step4?: Array<string>;
      step5?: string;
    })
  | null;
export type RootState = {
  user: UserDetails;
};

const initialState: RootState = {
  user: null,
};

const onBoardingSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    reset: () => {
      return { ...initialState };
    },
    setUser: (state, action: PayloadAction<Partial<UserDetails>>) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { reset, setUser } = onBoardingSlice.actions;

export default onBoardingSlice;
