import React from "react";
import "./styles.css";

const SecondaryButtonSmall = ({
  text,
  onClick,
  icon = null,
  containerStyles = "",
  iconStyles = "",
  textStyle = "",
  selected = false,
}: {
  text?: string;
  onClick?: () => void;
  icon?: any;
  containerStyles?: string;
  textStyle?: string;
  iconStyles?: string;
  selected?: boolean;
}) => {
  return (
    <div
      className={`relative secondary-btn flex  ${containerStyles} `}
      onClick={onClick}
    >
      {icon && <div className={`absolute left-2 ${iconStyles}`}>{icon}</div>}
      {text && <div className={`${textStyle}`}>{text || ""}</div>}
    </div>
  );
};

export default SecondaryButtonSmall;
