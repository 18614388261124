import React from "react";
import "./styles.css";
import SecondaryButton from "./SecondaryButton";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import { setUser } from "../redux/OnBoardingSlice";

const StepThree = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);

  const onContinue = (house = "1") => {
    dispatch(setUser({ step3: house }));
    navigate(AppPaths.ONBOARDING_FOUR);
  };
  const options = [
    { text: "Sneakers", icon: "Sneakers.png" },
    { text: "Sandals", icon: "Sandals.png" },
    { text: "Heels", icon: "highheel.png" },
    { text: "Boots", icon: "boots.png" },
    { text: "Other shoes", icon: "otherShoe.png" },
  ];

  return (
    <>
      <div className="mx-10 flex flex-col flex-1 ">
        {options.map((option, index) => (
          <SecondaryButton
            key={index} // Provide a unique key for each button
            onClick={() => onContinue(option.text)}
            text={option.text}
            icon={
              <img
                src={require(`../assets/${option.icon}`)}
                className="btn-icon"
                alt=""
              />
            }
            selected={user?.step3 === option.text}
            containerStyles={`btn-onb2 ${index !== 0 ? "mt-4" : "pt-1"}`}
            textStyle="btn-onb2-text"
          />
        ))}
      </div>
    </>
  );
};

export default StepThree;
