import React from "react";
import "./styles.css"; // Import your styling file

const Table = ({
  selected,
  onSelect,
  data,
}: {
  selected?: number;
  onSelect?: (index: number) => any;
  data?: Array<string>;
}) => {
  return (
    <div className="CustomTable">
      <div className="CustomTableBody mx-8">
        {data?.map((row, index) => (
          <div
            key={index}
            className={`TableRow ${index === 0 ? "firstRow" : ""} ${
              index === 6 ? "lastRow" : ""
            }
            ${selected === index && "secondary-btn-active"}
            `}
            onClick={() => {
              onSelect?.(index);
            }}
          >
            {selected === index ? (
              <div className={`secondary-btn-text-active`}>{row}</div>
            ) : (
              <div className={`TableData`}>{row}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Table;
