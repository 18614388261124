import { useState } from "react";
import TextInput from "../TextInput";
import "../../container/styles.css";
import ReactPlayer from "react-player";
import toast from "react-hot-toast";
import ApiService from "../../api/ApiServices";
import { Modal } from "../Unicorn";
import { baseURL } from "../../api/Api";
const HomeScrollVidFragment = ({
  onClick,
  imgStyle = null,
  contStyle = null,
  id,
  picUrl,
  handleSuccess,
  hideText,
  handleReadMore,
  data,
  isHallow,
  thumbnail
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  imgStyle?: any;
  contStyle?: any;
  id?: any,
  picUrl?: any,
  handleSuccess?: any,
  hideText?: any,
  handleReadMore?: any;
  data?: any;
  isHallow?: any;
  thumbnail?:any;
}) => {
  console.log('picUrl',picUrl);
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async () => {
    if (!inputValue) {
      toast.error('please add some text')
      return
    }
    try {
      const response = await ApiService.answerText({
        challengeId: id,
        answer: inputValue,
      });
      if (response?.status) {
        toast.success(response?.message)
        setInputValue('')
        handleSuccess(response)
      }
    } catch (error) {
      console.error("Error while submitting answer:", error);
    }
  }
  const getYouTubeVideoId = (url: string) => {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url?.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
  };
  return (
    <div
      onClick={onClick}
      className={`flex flex-col flex-1 home-frag-cont mr-3 ${contStyle && contStyle
        } relative`}
    >
      {/* <img
        alt=""
        className={`home-frag-img ${imgStyle}`}
        src={require("../../assets/homeFragVid.png")}
      /> */}
      {data?.additionalInfo !== "" && !isHallow && <button
        className="absolute top-3 right-4 text-sm font-bold h-8 w-8 text-black readmore-btn"
        onClick={() => handleReadMore(data?.additionalInfo)}
      >
        ?
      </button>}
      <div className={`home-frag-img overflow-hidden  ${imgStyle}`}>
        {isHallow ? <img src={thumbnail}  alt={thumbnail}  /> : <ReactPlayer
          width="100%"
          height="100%"
          style={{
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
            overflow: "hidden",
          }}
          playing
          url={picUrl}
          light={thumbnail}
        />}
      </div>

      {!hideText && <div className="flex flex-row items-center">
        <TextInput
          placeholder="Enter your answer"
          value={inputValue}
          containerStyles="ml-2 pl-2 py-1 my-2 "
          onChange={handleInputChange}
          textInputStyles={{ maxWidth: "96%",paddingLeft: 4, paddingRight: 4 }}
        />
        <div className={`primary-btn flex px-1 py-1 mx-2`} onClick={handleSubmit}>
          <div className={`inter-12-bold text-white px-1`}>SUBMIT</div>
        </div>
      </div>}
    </div>
  );
};

export default HomeScrollVidFragment;
