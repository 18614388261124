import React, { useState } from "react";
import "./styles.css";
import "../container/styles.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../components/SecondaryButton";
import PrimaryButton from "../components/PrimaryButton";
import { AppPaths } from "../utils/AppPaths";
import { ReactComponent as Message } from "../assets/email-verification.svg";
import TypeWriter from "../components/TypeWriter";

const EmailVerification = () => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  // const { step } = useAppSelector((state) => state.root);
  const [inputValue, setInputValue] = useState("");
  const [emailResend, setEmailResend] = useState(false);
  const [verified, setVerified] = useState(false);

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };
  const renderTopSvg = () => {
    return (
      <>
        <svg
          // width="100%"
          // height="610"
          viewBox="10 -25 350 450"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            // top: -40,
            //        left: -10,
            // right: -10,
            zIndex: -1,
            // maxHeight: "630px",
            // height: "90%",
            // left: 0,
            // right: 0,
          }}
        >
          <path
            d="M-64 125L27.3314 101.816C137.894 73.7501 253.837 74.7737 363.886 104.787L438 125"
            stroke="#FFD3F6"
            stroke-width="422"
          />
        </svg>
      </>
    );
  };

  const renderHeaderImage = () => {
    return (
      <>
        <div className="relative flex flex-col items-center ">
          {/* <img
          alt=""
          src={require("../assets/item9-m.png")}
          className="onb9-m1 "
        /> */}
          <Message />
          <span
            className="absolute text-center "
            style={{
              top: "5vh",
            }}
          >
            {verified ? (
              <TypeWriter key={6} text="DONE!" delay={40} />
            ) : (
              <TypeWriter key={7} text="Wait! One last step!." delay={40} />
            )}
          </span>
          <span
            className="absolute text-center"
            style={{
              bottom: "10vh",
              padding: "0px 16px"
            }}
          >
            {verified ? (
              <TypeWriter
                key={9}
                text="Yay! Your email has been verified."
                delay={40}
              />
            ) : (
              <TypeWriter
                key={10}
                text="Check your email to verify and activate your account."
                delay={40}
              />
            )}
          </span>
        </div>
        <img
          alt=""
          src={require("../assets/Item1.png")}
          className="onb1-2 mt-[-20px]"
        />
      </>
    );
  };

  return (
    <div className="flex flex-col h-svh relative z-10 items-center">
      {renderTopSvg()}

      <div className="z-11">
        <div
          className="mx-10"
          style={{
            marginTop: "3vh",
          }}
        >
          <div className="flex flex-col justify-center items-center" style={{}}>
            {renderHeaderImage()}
          </div>
          <div className="">
            {!verified && (
              <div className="sub-title-text-16 text-center mt-4">
                {emailResend
                  ? `We resent the verification email! Check your inbox. `
                  : "Didn’t get an email?"}
              </div>
            )}
            {!verified && (
              <SecondaryButton
                onClick={() => {
                  setEmailResend(true);
                  setTimeout(() => {
                    setVerified(true);
                  }, 1000);
                }}
                text="RESEND EMAIL"
                containerStyles="py-4 mt-3"
                textStyle="inter-bold-text-16"
              />
            )}
            <PrimaryButton
              onClick={() => {
                navigate(AppPaths.LOGIN);
              }}
              text="BACK TO LOGIN"
              containerStyles="py-3 mt-3"
              textStyle="inter-bold-text-16-white"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center flex-1 mt-5 "></div>
    </div>
  );
};

export default EmailVerification;
