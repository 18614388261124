import { useState } from "react";
import TextInput from "../TextInput";
import PrimaryButton from "../PrimaryButton";
import "../../container/styles.css";
import ApiService from "../../api/ApiServices";
import toast from "react-hot-toast";
import { Modal } from "../Unicorn";

const HomeScrollText = ({
  onClick,
  imgStyle = null,
  contStyle = null,
  id,
  picUrl,
  handleSuccess,
  handleReadMore,
  data,
}: {
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  imgStyle?: any;
  contStyle?: any;
  id?: any;
  picUrl?: any;
  handleSuccess?: any;
  handleReadMore?: any;
  data?: any;
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async () => {
    if (!inputValue) {
      toast.error('please add some text')
      return
    }
    try {
      const response = await ApiService.answerText({
        challengeId: id,
        answer: inputValue,
      });
      if (response?.status) {
        toast.success(response?.message)
        setInputValue('')
        handleSuccess(response)
      }
    } catch (error) {
      console.error("Error while submitting answer:", error);
    }
  }
  return (
    <div
      onClick={onClick}
      className={`relative flex flex-col flex-1 home-frag-cont mr-3 ${contStyle && contStyle
        }`}
    > {data?.additionalInfo !== "" && <button
      className="absolute top-3 right-4 text-sm font-bold h-8 w-8 text-black readmore-btn"
      onClick={() => handleReadMore(data?.additionalInfo)}
    >
      ?
    </button>}
      <img
        alt=""
        className={`home-frag-img ${imgStyle}`}
        src={picUrl || require("../../assets/homeFragText.png")}
      />
      <div className="flex flex-row items-center">
        <TextInput
          placeholder="Enter your answer"
          value={inputValue}
          containerStyles="ml-3 pl-2 py-2 my-2 flex flex-1"
          onChange={handleInputChange}
          textInputStyles={{ maxWidth: "96%", paddingLeft: 4, paddingRight: 4 }}
        />
        <div className={`primary-btn flex px-1 py-1 mx-2`} onClick={handleSubmit}>
          <div className={`inter-12-bold text-white px-1 cursor-pointer`}>SUBMIT</div>
        </div>
      </div>
    </div>
  );
};

export default HomeScrollText;
