import React, { useState } from "react";
import "./styles.css";
import "../container/styles.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SecondaryButton from "../components/SecondaryButton";
import PrimaryButton from "../components/PrimaryButton";
import { AppPaths } from "../utils/AppPaths";
import { ReactComponent as Message } from "../assets/ForgotPass.svg";
import TypeWriter from "../components/TypeWriter";
import TextInput from "../components/TextInput";
import { isValidEmail } from "../utils/utils";
import ApiService from "../api/ApiServices";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  // const { step } = useAppSelector((state) => state.root);
  const [inputValue, setInputValue] = useState("");
  const [emailResend, setEmailResend] = useState(false);
  const [emailValidate, setEmailValidate] = useState<boolean>(false);
  const [verified, setVerified] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  const renderTopSvg = () => {
    return (
      <>
        <svg
          viewBox="10 -25 350 450"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            zIndex: -1,
          }}
        >
          <path
            d="M-64 125L27.3314 101.816C137.894 73.7501 253.837 74.7737 363.886 104.787L438 125"
            stroke="#FFD3F6"
            stroke-width="422"
          />
        </svg>
      </>
    );
  };

  const handleInputChange = (e: string) => {
    setEmailValidate(!isValidEmail(e))
    setInputValue(e)
  };

  const renderHeaderImage = () => {
    return (
      <>
        <div className="relative flex flex-col items-center ">
          <Message />
          {/* <span
            className="absolute text-center "
            style={{
              top: "7vh",
              right: "1vh",
              left: "1vh",
            }}
          >
            <>
              <TypeWriter key={7} text="Forgot your username or" delay={40} />
              <TypeWriter key={7} text="password ? No problem!" delay={40} />
            </>
          </span> */}
        </div>
        <img
          alt=""
          src={require("../assets/Forgot.png")}
          className="onb1-2 mt-0"
        />
      </>
    );
  };

  const sendEmail = async (data: any) => {
    try {
      const response = await ApiService.sendResetEmail({
        email: data,
      });
      if (response.status) {
        toast.success('Mail has been send! Please check your register email')
      }
      setInputValue('')
    } catch (error: any) {
      alert(error?.message);
    }
  }

  return (
    <div className="flex flex-col h-svh relative z-10 items-center">
      {renderTopSvg()}

      <div className="z-11">
        <div
          className="mx-10"
          style={{
            marginTop: "3vh",
          }}
        >
          <div className="flex flex-col justify-center items-center" style={{}}>
            {renderHeaderImage()}
          </div>
          <div className="mt-20">
            <TextInput
              placeholder="Enter email"
              value={inputValue}
              onChange={(val) => handleInputChange(val.target.value)}
              containerStyles="px-3 py-3"
            />
            {emailValidate && <span className="mt-4 flex" style={{ color: 'red' }}>Email address is not valid!</span>}
            <PrimaryButton
              onClick={() => {
                localStorage.setItem('forgotMail', inputValue);
                if (id) {
                  localStorage.setItem('id', id);
                }
                sendEmail(inputValue)
                // navigate(AppPaths.RESET_PASSWORD);
              }}
              disable={emailValidate || !inputValue}
              text="SEND RESET LINK"
              containerStyles="py-2 mt-5"
              textStyle="inter-bold-text-16-white"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center flex-1 mt-5 "></div>
    </div>
  );
};

export default ForgotPassword;
