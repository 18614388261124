import React, { useState, useEffect } from "react";
import "./styles.css";

const TypeWriter = ({
  text = "",
  text2 = "",
  delay = 0,
}: {
  text: string;
  text2?: string;
  delay?: number;
}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentBoldText, setCurrentBoldText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const words = text.split(" ");

    if (currentIndex < words.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + words[currentIndex] + " ");
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 60);

      return () => clearTimeout(timeout);
    } else if (
      currentIndex >= words.length &&
      currentIndex < words.length + text2.split(" ").length
    ) {
      const boldTimeout = setTimeout(() => {
        setCurrentBoldText((prevBoldText) => {
          const boldWords = text2.split(" ");
          return prevBoldText + boldWords[currentIndex - words.length] + " ";
        });
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 60);

      return () => clearTimeout(boldTimeout);
    }
  }, [currentIndex, delay, text, text2]);

  return (
    <span className=" chilanka-regular-18  ">
      {currentText}
      <span className="cherry-bomb-regular-21"> {currentBoldText}</span>
    </span>
  );
};

export default TypeWriter;
