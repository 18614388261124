import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react";
import { baseURL } from "../../api/Api";
import UserCardHeader from "./UserCardHeader";

const UserFeedCard = ({
  imageUrl = null,
  header = "",
  subHeader = "",
  type = "text",
  data = null,
}: {
  imageUrl?: string | null;
  header?: string;
  subHeader?: string;
  type: any;
  data?: any;
}) => {
  return (
    <div className="flex flex-1 flex-col px-3 pb-3 pt-5">
      <UserCardHeader data={data} />
      {type === "image" ? (
        <div className="flex flex-1">
          <img
            alt=""
            src={baseURL + data?.answer || "https://picsum.photos/200/300"}
            className="user-cardimage flex flex-1"
          />
        </div>
      ) : type === "video" ? (
        <div className="inter-regular-24">{data?.answer}</div>
      ) : <div className="inter-regular-24">{data?.answer}</div>
//       <div>{data?.metadata?.questions?.map((item: {
//         [x: string]: ReactNode; answer: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined; 
// }, index: any) => {
//         return <>
//         <div className="inter-regular-16 font-light">{`${index + 1}.`}{item?.q}</div>
//         <div className="inter-regular-14 font-extrabold">Answer : {item?.answer || '-'}</div>
//         </>
//       })}</div>
      }
    </div>
  );
};

export default UserFeedCard;
