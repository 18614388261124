import { useSpring, animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ApiService from "../../api/ApiServices";
import LoadImage from '../../assets/loader.gif';
import { AppPaths } from "../../utils/AppPaths";
import { Modal } from "../Unicorn";
import TextInput from "../TextInput";

interface Question {
  _id: string;
  q: string;
  options: { [key: string]: string };
}

interface QuizData {
  challenges: {
    title: string;
    metadata: {
      questions: Question[];
      isUrl?: boolean;
      url?: string;
    };
  };
  data?: any;
}

interface PrimaryButtonProps {
  text: string;
  onClick: () => void;
  containerStyles?: string;
  textStyle?: string;
  disabled?: boolean;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  onClick,
  containerStyles = "",
  textStyle = "",
  disabled = false,
}) => {
  return (
    <div
      className={`primary-btn w-full ${containerStyles} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
      onClick={disabled ? undefined : onClick}
    >
      <span className={`${textStyle}`}>{text}</span>
    </div>
  );
};

const TimeQuiz: React.FC = () => {
  const [isPopped, setIsPopped] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [quizResult, setQuizResult] = useState("");
  const [isChecked, setChecked] = useState<{ [key: string]: string }>({});
  const [videoQuiz, setVideoQuiz] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
    navigate(AppPaths.HOME);
  };
  const [load, setLoad] = useState<boolean>(false);
  const [quizData, setQuizData] = useState<QuizData | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const popAnimation = useSpring({
    transform: isPopped ? "scale(1)" : "scale(0.5)",
    config: {
      duration: 100,
    },
  });

  const handleOptionClick = (questionId: string, optionKey: string) => {
    setChecked((prevChecked) => ({
      ...prevChecked,
      [questionId]: optionKey,
    }));
  };

  const springProps1 = useSpring({
    from: { marginRight: "100%" },
    to: { marginRight: "0%" },
    config: { duration: 200 },
  });

  const getQuizData = async () => {
    setLoad(true);
    const response = await ApiService.getQuizById(id);
    setQuizData(response?.data);
    if (response?.data?.challenges?.metadata?.isUrl) {
      setVideoQuiz(true);
    }
    setLoad(false);
  };

  const handleOpenURL = () => {
    if (quizData?.challenges?.metadata?.url) {
      window.open(quizData.challenges.metadata.url, '_blank');
    }
  };

  useEffect(() => {
    if (id) {
      getQuizData();
    }
  }, [id]);
  const handleQuiz = async () => {
    let quizDataPayload;
    if (!videoQuiz && quizData?.challenges?.metadata?.questions) {
      quizDataPayload = {
        challengeId: id,
        type: "quiz",
        metadata: {
          questions: quizData.challenges.metadata.questions.map((question) => {
            const selectedOption = isChecked[question._id];
            const selectedAnswer = question.options[selectedOption] ?? "";
            return {
              q: question.q,
              answer: selectedAnswer,
            };
          }),
        },
      };
    } else {
      quizDataPayload = {
        challengeId: id,
        type: "quiz",
        metadata: {},
        answer: inputValue,
      };
    }

    try {
      const response = await ApiService.answerText(quizDataPayload);
      if (response?.status) {
        toast.success(response?.message);
        setQuizResult(response?.data?.answer)
        setShowResult(true);
        setTimeout(() => {
          setShowResult(false);
          setIsOpen(true);
        }, 5000);
      }
    } catch (error) {
      console.error("Error while answering quiz", error);
    }
  };

  useEffect(() => {
    setIsPopped(true);
  }, []);

  const renderTopSvg = () => {
    return (
      <svg
        viewBox="0 0 375 454"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: -40,
          zIndex: -1,
        }}
      >
        <path
          d="M-64 125L27.3314 101.816C137.894 73.7501 253.837 74.7737 363.886 104.787L438 125"
          stroke="#FFD3F6"
          strokeWidth="213"
        />
      </svg>
    );
  };

  const renderHeaderImage = () => {
    return (
      <span className="flex flex-1 justify-center relative">
        <animated.img
          style={{ ...popAnimation }}
          alt=""
          src={require("../../assets/Item1.png")}
          className="onb1-2 inline-block mr-[-10px]"
        />
        <animated.div className="" style={{ ...springProps1 }}>
          <img
            alt=""
            src={require("../../assets/time_quiz.png")}
            className="onb1-2-m1"
          />
        </animated.div>
      </span>
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedName = e.target.value;
    setInputValue(updatedName);
  };

  const handleNextClick = () => {
    const questionsLength = quizData?.challenges?.metadata?.questions?.length ?? 0;
    if (currentQuestionIndex < questionsLength - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleQuiz();
    }
  };

  const ResultDisplay: React.FC<{ result: string }> = ({ result }) => {
    return (
      <div className="flex flex-col relative z-10">
        <div
          className="absolute right-1 top-2 flex flex-row justify-end px-2 py-1"
          onClick={() => navigate(-1)}
        >
          <img
            className="h-8 w-8"
            alt=""
            src={require("../../assets/close.png")}
          />
        </div>
        {renderTopSvg()}
        <div className="z-11">
          <div className="mt-10 mx-3">{renderHeaderImage()}</div>
        </div>
        <div className="fixed inset-0 flex items-center justify-center">
          <div className="text-xl font-bold">Your Result is - </div>&nbsp;
          <div className="text-xl font-bold"> {result} </div>
        </div>
      </div>
    );
  };

  const currentQuestion = quizData?.challenges?.metadata?.questions?.[currentQuestionIndex];
  const isNextButtonDisabled = (!videoQuiz && !isChecked[currentQuestion?._id ?? ""]);
  const isNextForVideoDisabled = videoQuiz && !inputValue
  return (
    <>
      {showResult ? <ResultDisplay result={quizResult} /> : <>
        {load ? (
          <div className="flex justify-center items-center h-screen mb-5">
            <img width="100px" className="center" src={LoadImage} alt="loader" />
          </div>
        ) : (
          <>
            <div className="flex flex-col relative z-10">
              <div
                className="absolute right-1 top-2 flex flex-row justify-end px-2 py-1"
                onClick={() => navigate(-1)}
              >
                <img
                  className="h-8 w-8"
                  alt=""
                  src={require("../../assets/close.png")}
                />
              </div>
              {renderTopSvg()}
              <div className="z-11">
                <div className="mt-10 mx-3">{renderHeaderImage()}</div>
              </div>
              {videoQuiz ? (
                <div className="flex flex-col flex-1 mt-5 py-8 mx-3 overflow-y-auto">
                  <div>
                    <span className="cursor-pointer underline flex justify-center" onClick={handleOpenURL}>
                      Open URL
                    </span>
                    <TextInput
                      placeholder="Enter your answer"
                      value={inputValue}
                      onChange={handleInputChange}
                      containerStyles="px-3 py-3 flex flex-1 mt-5"
                    />
                    <div className="flex justify-center mt-4 mb-8">
                      <PrimaryButton
                        text={"SUBMIT"}
                        onClick={handleQuiz}
                        containerStyles="py-3 mt-3 mx-2 mb-3"
                        textStyle="primary-btn-text"
                        disabled={isNextForVideoDisabled}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col flex-1 mt-5 py-8 mx-3 overflow-y-auto">
                  <div className="flex flex-row justify-between items-baseline">
                    <div className="sora-semibold-24">{quizData?.challenges?.title}</div>
                    <div className="sora-semibold-14">{quizData?.challenges?.metadata?.questions?.length ?? 0} Q's</div>
                  </div>
                  {currentQuestion && (
                    <div key={currentQuestion._id} className="mt-3">
                      <div className="sora-semibold-16 text-justify">
                        {currentQuestion.q}
                      </div>
                      {Object.entries(currentQuestion.options).map(
                        ([key, value]) => (
                          <div
                            key={key}
                            className="option-cont flex flex-row px-3 py-3 mt-3"
                            onClick={() => handleOptionClick(currentQuestion._id, key)}
                          >
                            <label className="checkbox-container">
                              <input
                                type="checkbox"
                                checked={isChecked[currentQuestion._id] === key}
                                onChange={() => handleOptionClick(currentQuestion._id, key)}
                              />
                              <span
                                className={`checkmark checkmark-round ${isChecked[currentQuestion._id] === key ? `checkmark-checked` : ''
                                  }`}
                              />
                            </label>
                            <div className="sora-regular-16 pl-2 flex items-center">{value}</div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="flex justify-center mt-4 mb-8">
              {!videoQuiz && <PrimaryButton
                text={
                  currentQuestionIndex === (quizData?.challenges?.metadata?.questions?.length ?? 0) - 1
                    ? "SUBMIT"
                    : "NEXT"
                }
                onClick={handleNextClick}
                containerStyles="py-3 mt-3 mx-2 mb-3"
                textStyle="primary-btn-text"
                disabled={isNextButtonDisabled}
              />}
            </div>
          </>
        )}
        {isOpen && <Modal isOpen={isOpen} onClose={handleClose} />}
      </>}
    </>

  );
};

export default TimeQuiz;