export const happyImages = {
  Happy: require("../assets/reaction/happy.png"),
  Confident: require("../assets/reaction/Confident.png"),
  Connected: require("../assets/reaction/Connected.png"),
  Excited: require("../assets/reaction/Excited.png"),
  Loved: require("../assets/reaction/Loved.png"),
  Empowered: require("../assets/reaction/Empowered.png"),
  Vibing: require("../assets/reaction/Vibing.png"),
  Proud: require("../assets/reaction/proud.png"),
  Grateful: require("../assets/reaction/Grateful.png"),
  Energized: require("../assets/reaction/Energized.png"),
};

export const difficultImage = {
  Aggressive: require("../assets/reaction/difficult/Aggressive.png"),
  AllAlone: require("../assets/reaction/difficult/AllAlone.png"),
  Disappointed: require("../assets/reaction/difficult/Disappointed.png"),
  Furious: require("../assets/reaction/difficult/Furious.png"),
  Hurt: require("../assets/reaction/difficult/Hurt.png"),
  Jealous: require("../assets/reaction/difficult/Jealous.png"),
  Mad: require("../assets/reaction/difficult/Mad.png"),
  Sad: require("../assets/reaction/difficult/Sad.png"),
  Sick: require("../assets/reaction/difficult/Sick.png"),
  Tired: require("../assets/reaction/difficult/Tired.png"),
};

export const InBetweenImage = {
  Relaxed: require("../assets/reaction/inBetween/Relaxed.png"),
  Calm: require("../assets/reaction/inBetween/Calm.png"),
  Thoughtful: require("../assets/reaction/inBetween/Thoughtful.png"),
  Helpless: require("../assets/reaction/inBetween/Helpless.png"),
  Bored: require("../assets/reaction/inBetween/Bored.png"),
  Content: require("../assets/reaction/inBetween/Content.png"),
  Nervous: require("../assets/reaction/inBetween/Nervous.png"),
  Overthinking: require("../assets/reaction/inBetween/Overthinking.png"),
  Critical: require("../assets/reaction/inBetween/Critical.png"),
  Sentimental: require("../assets/reaction/inBetween/Sentimental.png"),
};

export const AnxiousImages = {
  Stressed: require("../assets/reaction/Anxious/Stressed.png"),
  Unprepared: require("../assets/reaction/Anxious/Unprepared.png"),
  Scared: require("../assets/reaction/Anxious/Scared.png"),
  Worried: require("../assets/reaction/Anxious/Worried.png"),
  Insecure: require("../assets/reaction/Anxious/Insecure.png"),
  Behind: require("../assets/reaction/Anxious/Behind.png"),
  Overloaded: require("../assets/reaction/Anxious/Overloaded.png"),
  Overwhelmed: require("../assets/reaction/Anxious/Overwhelmed.png"),
  Stupid: require("../assets/reaction/Anxious/Stupid.png"),
  Worthless: require("../assets/reaction/Anxious/Worthless.png"),
};

export const emojiImages = {
  happyImages: {
      Happy: "../assets/reaction/happy.png",
      Confident: "../assets/reaction/Confident.png",
      Connected: "../assets/reaction/Connected.png",
      Excited: "../assets/reaction/Excited.png",
      Loved: "../assets/reaction/Loved.png",
      Empowered: "../assets/reaction/Empowered.png",
      Vibing: "../assets/reaction/Vibing.png",
      Proud: "../assets/reaction/proud.png",
      Grateful: "../assets/reaction/Grateful.png",
      Energized: "../assets/reaction/Grateful.png", // Note: Energized is duplicated, please adjust if necessary
  },
  difficultImage: {
      Aggressive: "../assets/reaction/difficult/Aggressive.png",
      AllAlone: "../assets/reaction/difficult/AllAlone.png",
      Disappointed: "../assets/reaction/difficult/Disappointed.png",
      Furious: "../assets/reaction/difficult/Furious.png",
      Hurt: "../assets/reaction/difficult/Hurt.png",
      Jealous: "../assets/reaction/difficult/Jealous.png",
      Mad: "../assets/reaction/difficult/Mad.png",
      Sad: "../assets/reaction/difficult/Sad.png",
      Sick: "../assets/reaction/difficult/Sick.png",
      Tired: "../assets/reaction/difficult/Tired.png",
  },
  InBetweenImage: {
      Relaxed: "../assets/reaction/inBetween/Relaxed.png",
      Calm: "../assets/reaction/inBetween/Calm.png",
      Thoughtful: "../assets/reaction/inBetween/Thoughtful.png",
      Helpless: "../assets/reaction/inBetween/Helpless.png",
      Bored: "../assets/reaction/inBetween/Bored.png",
      Content: "../assets/reaction/inBetween/Content.png",
      Nervous: "../assets/reaction/inBetween/Nervous.png",
      Overthinking: "../assets/reaction/inBetween/Overthinking.png",
      Critical: "../assets/reaction/inBetween/Critical.png",
      Sentimental: "../assets/reaction/inBetween/Sentimental.png",
  },
  AnxiousImages: {
      Stressed: "../assets/reaction/Anxious/Stressed.png",
      Unprepared: "../assets/reaction/Anxious/Unprepared.png",
      Scared: "../assets/reaction/Anxious/Scared.png",
      Worried: "../assets/reaction/Anxious/Worried.png",
      Insecure: "../assets/reaction/Anxious/Insecure.png",
      Behind: "../assets/reaction/Anxious/Behind.png",
      Overloaded: "../assets/reaction/Anxious/Overloaded.png",
      Overwhelmed: "../assets/reaction/Anxious/Overwhelmed.png",
      Stupid: "../assets/reaction/Anxious/Stupid.png",
      Worthless: "../assets/reaction/Anxious/Worthless.png",
  },
};


type FeelingType = string;

export function getImageUrl(feeling: FeelingType): string {
  let imageUrl = "";

  // Check which category the feeling belongs to
  if ((feeling as keyof typeof happyImages) in happyImages) {
      imageUrl = happyImages[feeling as keyof typeof happyImages];
  } else if ((feeling as keyof typeof difficultImage) in difficultImage) {
      imageUrl = difficultImage[feeling as keyof typeof difficultImage];
  } else if ((feeling as keyof typeof InBetweenImage) in InBetweenImage) {
      imageUrl = InBetweenImage[feeling as keyof typeof InBetweenImage];
  } else if ((feeling as keyof typeof AnxiousImages) in AnxiousImages) {
      imageUrl = AnxiousImages[feeling as keyof typeof AnxiousImages];
  } else {
      // Default image if feeling is not found
      imageUrl = ""; // You can set a default image URL here
  }

  return imageUrl;
}

export function getImageType(feeling: FeelingType): string {
  let name = "";

  // Check which category the feeling belongs to
  if ((feeling as keyof typeof happyImages) in happyImages) {
      name = "Good";
  } else if ((feeling as keyof typeof difficultImage) in difficultImage) {
      name = "Difficult";
  } else if ((feeling as keyof typeof InBetweenImage) in InBetweenImage) {
      name = "In-Between";
  } else if ((feeling as keyof typeof AnxiousImages) in AnxiousImages) {
      name = "Anxious";
  } else {
      // Default image if feeling is not found
      name = ""; // You can set a default image URL here
  }

  return name;
}





export const colorsBg: Record<
  "Happy" | "difficult" | "InBetween" | "Anxious",
  string
> = {
  Happy: "#FFD3F6",
  difficult: "#FFFCBF",
  InBetween: "#CEE1FF",
  Anxious: "#D7F8E8",
};

export const HappyKey = Object.keys(happyImages);
export const DifficultKey = Object.keys(difficultImage);
export const InBetweenKeys = Object.keys(InBetweenImage);
export const AnxiousKey = Object.keys(AnxiousImages);

export const getColor = (title: string) => {
  if (HappyKey?.includes(title)) return colorsBg.Happy;
  if (DifficultKey?.includes(title)) return colorsBg.difficult;
  if (InBetweenKeys?.includes(title)) return colorsBg.InBetween;
  if (AnxiousKey?.includes(title)) return colorsBg.Anxious;
};
