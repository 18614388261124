export type ApiUrl = string;

export enum ApiRequestType {
  GET = "get",
  DELETE = "delete",
  POST = "post",
  PUT = "put",
  PATCH = "patch",
}

export type Method = ApiRequestType;

export type AccessToken = string | undefined;

export type RouteName = string;

export type Params = object | undefined;

export interface User {
  name: string;
  email: string;
  username: string;
  dob: Record<string, string>;
  role: string;
  other?: string;
  // grade: string;
  gurdians: Record<string, string>;
  city: string;
  state: string;
  school: string;
  // houseId: string;
  sisterId: string;
  password: string;
  instagramId: string;
}
