import PrimaryButton from "../PrimaryButton";
import "../../container/styles.css";
import { useNavigate } from "react-router-dom";
import { AppPaths } from "../../utils/AppPaths";
import { useState } from "react";

const HomeScrollQuizFragment = ({
  onClick,
  btnClick,
  imgStyle = null,
  contStyle = null,
  picUrl,
  id,
  handleReadMore,
  data,
  noMore,
}: // handleSuccess,
{
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  btnClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  imgStyle?: any;
  contStyle?: any;
  picUrl?: any;
  id?: any;
  handleReadMore?: any;
  data?: any;
  noMore?: any;
  // handleSuccess?: any;
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col home-frag-cont mr-3 relative ${
        contStyle && contStyle
      }`}
    >
      {!noMore && data?.additionalInfo !== "" && <button
        className="absolute top-3 right-4 text-sm font-bold h-8 w-8 text-black readmore-btn"
        onClick={() => handleReadMore(data?.additionalInfo)}
      >
        ?
      </button>}
      <div onClick={() => navigate(`${AppPaths.TIME_QUIZ}?id=${id}`)}>
        <img
          alt=""
          className={`home-frag-img ${imgStyle}`}
          src={picUrl || require("../../assets/homeFragQuiz.png")}
        />
      </div>
      {!noMore && <div className="flex flex-row flex-1 items-center px-10">
        <PrimaryButton
          text="TAKE 2 MIN QUIZ"
          onClick={() => navigate(`${AppPaths.TIME_QUIZ}?id=${id}`)}
          containerStyles="px-1 py-1 flex flex-1 my-2"
          textStyle="inter-12-bold"
        />
      </div>}
    </div>
  );
};

export default HomeScrollQuizFragment;