import { createSlice } from "@reduxjs/toolkit";
import secureLocalStorage from "react-secure-storage";
import { AppPaths } from "../utils/AppPaths";

export const tabData = [
  {
    id: 1,
    url: require("../assets/message_text.png"),
    selectedUrl: require("../assets/message_text.png"),
    path: AppPaths.MYJOURNEY,
  },
  {
    id: 2,
    url: require("../assets/message_text.png"),
    selectedUrl: require("../assets/selectedMessage.png"),
    alertUrl: require("../assets/MessagesAlert.png"),
    path: AppPaths.MESSAGES,
  },
  {
    id: 3,
    url: require("../assets/Home.png"),
    selectedUrl: require("../assets/icon_house.png"),
    path: AppPaths.HOME,
  },
  {
    id: 4,
    url: require("../assets/ring.png"),
    selectedUrl: require("../assets/SelectedRing.png"),
    path: AppPaths.MOODRING,
  },
  {
    id: 5,
    url: require("../assets/menu.png"),
    selectedUrl: require("../assets/bar.png"),
    path: AppPaths.PROFILE,
  },
];

export type RootState = {
  root: null;
  step: number;
  isPopped: boolean;
  shouldRender: boolean;
  login: boolean;
  emojiModal: boolean;
  pointsTracker: boolean;
  selectedTab: number;
  selectedTabUrl: string;
  isModalAgeOpen: boolean;
  userData: null,
  pointData: null,
};

const initialState: RootState = {
  root: null,
  step: 1,
  isPopped: false,
  shouldRender: false,
  login: false,
  emojiModal: false,
  pointsTracker: false,
  selectedTab: 3,
  selectedTabUrl: "",
  isModalAgeOpen: false,
  userData: null,
  pointData: null,
};

const rootSlice = createSlice({
  name: "root",
  initialState,
  reducers: {
    reset: () => {
      return { ...initialState };
    },
    setStep: (state, action) => {
      state.step = action.payload;
      state.isPopped = false;
      state.shouldRender = false;
    },
    setAnimation: (state, action) => {
      state.isPopped = action.payload;
      state.shouldRender = action.payload;
    },
    setLogin: (state, action) => {
      state.login = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setLogout: (state) => {
      state.login = false;
      secureLocalStorage.removeItem("user");
      localStorage.removeItem("userId");
      window.location.href = AppPaths.LOGIN;
    },
    setEmojiModal: (state, action) => {
      state.emojiModal = action.payload;
    },
    setPointsTracker: (state, action) => {
      state.pointsTracker = action.payload;
    },
    setActiveTab: (state, action) => {
      state.selectedTab = action.payload;
      const selectedTabData = tabData.find(tab => tab.id === action.payload);
      if (selectedTabData) {
        state.selectedTabUrl = selectedTabData.selectedUrl;
      }
    },
    setAgeModal: (state, action) => {
      state.isModalAgeOpen = action.payload;
    },
    setPointData: (state, action) => {
      state.pointData = action.payload;
    }
  },
});

// export const rootReducer = rootSlice.reducer;
export const {
  reset,
  setStep,
  setAnimation,
  setLogin,
  setUserData,
  setEmojiModal,
  setPointsTracker,
  setLogout,
  setActiveTab,
  setAgeModal,
  setPointData,
} = rootSlice.actions;

export default rootSlice;
