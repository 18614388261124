import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../../components/SecondaryButton";
import { AppPaths } from "../../../utils/AppPaths";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { setLogout, setUserData } from "../../../redux/rootSlice";
import toast from "react-hot-toast";
import { baseURL } from "../../../api/Api";
import { ChangeEvent, useEffect, useState } from "react";
import LoadImage from '../../../assets/loader.gif';
import ApiService from "../../../api/ApiServices";
import Avatar from 'react-avatar';

const ProfileDetails = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector<any>(state => state.root.userData);
  const [profile, setProfile] = useState<any>('');
  const [load, setLoad] = useState<boolean>(false);
  const [apiData, setApiData] = useState<any>(null);
  const getProfileData = async () => {
    setLoad(true);
    const response = await ApiService.getProfile();
    setApiData(response?.data);
    dispatch(setUserData(response?.data?.user))
    setLoad(false)
  }

  useEffect(() => {
    // if (!userDetails?.name) {
      getProfileData()
    // }
  }, [])
  const renderTopGradient = () => (
    <svg
      // width="375"
      // height="136"
      viewBox="0 0 375 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: 45,
        zIndex: -10,
      }}
    >
      <path
        d="M-40 0C131.539 35.3624 227.734 55.5279 394 0V136C216.223 58.3725 121.228 57.7815 -40 136V0Z"
        fill="url(#paint0_linear_1270_1558)"
        fill-opacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1270_1558"
          x1="-9.47784"
          y1="67.7841"
          x2="355.534"
          y2="67.7841"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E8A4E0" />
          <stop offset="0.197917" stop-color="#ECC542" />
          <stop offset="0.416667" stop-color="#F5F552" />
          <stop offset="0.578125" stop-color="#B6EC80" />
          <stop offset="0.75" stop-color="#69E2E3" />
          <stop offset="0.979167" stop-color="#C0A4EE" />
        </linearGradient>
      </defs>
    </svg>
  );
  const renderCard = () => {
    return (
      <div className="flex flex-1 flex-col mt-9 mx-5 my-journey-card">
        <div className="py-1">
          <div
            onClick={() => {
              navigate(AppPaths.EDIT_USERNAME);
            }}
            className="flex flex-row mx-2 items-center"
          >
            <div className="inter-16-bold width-tab1">username:</div>
            <div className="flex flex-1 sub-title-text-16 ml-5">{userDetails?.name}</div>
            <img
              alt=""
              className="h-10 w-10"
              src={require("../../../assets/chevron_right_pink.png")}
            />
          </div>
        </div>

        <div className="py-1">
          <div
            onClick={() => {
              navigate(AppPaths.EDIT_PASSWORD);
            }}
            className="flex flex-row mx-2 items-center"
          >
            <div className="inter-16-bold width-tab1">password:</div>
            {/* <div className="flex flex-1 sub-title-text-16 ml-5">{userDetails?.questions?.['Time to get your keys!']?.password}</div> */}
            <div className="flex flex-1 sub-title-text-16 ml-5">*****</div>
            <img
              alt=""
              className="h-10 w-10"
              src={require("../../../assets/chevron_right_pink.png")}
            />
          </div>
        </div>

        {(userDetails?.questions?.['What grade are you in?'] !== '6th Grade' &&
          userDetails?.questions?.['What grade are you in?'] !== '7th Grade') && <div className="py-1">
            <div
              onClick={() => {
                navigate(AppPaths.EDIT_LOCATION);
              }}
              className="flex flex-row mx-2 items-center"
            >
              <div className="inter-16-bold width-tab1">location:</div>
              <div className="flex flex-1 sub-title-text-16 ml-5">
                {(userDetails?.questions?.['What grade are you in?'] === '6th Grade' ||
                  userDetails?.questions?.['What grade are you in?'] === '7th Grade') ? '-' : userDetails?.questions?.['Where are you from?']?.city + ', ' + userDetails?.questions?.['Where are you from?']?.state}
              </div>
              <img
                alt=""
                className="h-10 w-10"
                src={require("../../../assets/chevron_right_pink.png")}
              />
            </div>
          </div>}

        {(userDetails?.questions?.['What grade are you in?'] === '6th Grade' ||
          userDetails?.questions?.['What grade are you in?'] === '7th Grade') && <div className="py-1">
            <div
              onClick={() => {
                navigate(AppPaths.EDIT_BIRTHDAY);
              }}
              className="flex flex-row mx-2 items-center"
            >
              <div className="inter-16-bold width-tab1">birthday:</div>
              <div className="flex flex-1 sub-title-text-16 ml-5">{userDetails?.dob || '-'}</div>
              <img
                alt=""
                className="h-10 w-10"
                src={require("../../../assets/chevron_right_pink.png")}
              />
            </div>
          </div>}

        <div className="py-1">
          <div
            onClick={() => {
              navigate(AppPaths.EDIT_GRADE);
            }}
            className="flex flex-row mx-2 items-center"
          >
            <div className="inter-16-bold width-tab1">grade:</div>
            <div className="flex flex-1 sub-title-text-16 ml-5">
              {userDetails?.questions?.['What grade are you in?']}
            </div>
            <img
              alt=""
              className="h-10 w-10"
              src={require("../../../assets/chevron_right_pink.png")}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderProfile = () => {

    const updateUserProfile = async (profile: string) => {
      try {
        const response = await ApiService.updateUserDetails({
          profileUrl: profile,
        });
        if (response?.status) {
          toast.success(response?.message)
          setProfile(response?.data?.profileUrl)
          dispatch(setUserData(response.data.user.profileUrl));
        }
      } catch (error) {
        console.error("Error while updating user profile", error);
      }
    }

    function handleFileUpload(event: ChangeEvent<HTMLInputElement>) {
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      const file = files[0];
      const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (!validImageTypes.includes(file.type)) {
        toast.error('Please upload a valid image file (JPEG, PNG, or jpg).');
        return;
      }
      const formData = new FormData();
      formData.append('file', file);

      fetch(`${baseURL}/upload`, {
        method: 'POST',
        body: formData
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to upload file');
          }
          return response.json();
        })
        .then(data => {
          updateUserProfile(data?.data)
        })
        .catch(error => {
          toast.error('Error uploading file:', error);
        });
    }

    return (
      <div className="flex items-center justify-center content-center">
        <div className="relative w-[160px] h-[160px]">
          {userDetails?.profileUrl ? <img
            alt=""
            className="profile-img absolute top-5"
            src={(profile === '' ? userDetails?.profileUrl ? baseURL + userDetails?.profileUrl : "https://picsum.photos/200/200" : baseURL + profile)}
            height={150}
            width={150}
          /> : <div className="profile-img absolute top-5">
            <Avatar name={userDetails?.name} size="156" round color="white" fgColor="black" />
          </div>}
          <label htmlFor="file-upload" className="profile-update-btn absolute bottom-0 right-0 flex justify-center content-center cursor-pointer">
            <span className="svg-wrapper mt-[0.5rem] ml-1">
              <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.00016 16.9999C1.54183 16.9999 1.14947 16.8367 0.823079 16.5103C0.496691 16.1839 0.333496 15.7916 0.333496 15.3333V5.33325C0.333496 4.87492 0.496691 4.48256 0.823079 4.15617C1.14947 3.82978 1.54183 3.66659 2.00016 3.66659H4.62516L6.16683 1.99992H11.1668V3.66659H6.896L5.37516 5.33325H2.00016V15.3333H15.3335V7.83325H17.0002V15.3333C17.0002 15.7916 16.837 16.1839 16.5106 16.5103C16.1842 16.8367 15.7918 16.9999 15.3335 16.9999H2.00016ZM15.3335 5.33325V3.66659H13.6668V1.99992H15.3335V0.333252H17.0002V1.99992H18.6668V3.66659H17.0002V5.33325H15.3335ZM8.66683 14.0833C9.7085 14.0833 10.5939 13.7187 11.3231 12.9895C12.0522 12.2603 12.4168 11.3749 12.4168 10.3333C12.4168 9.29158 12.0522 8.40617 11.3231 7.677C10.5939 6.94783 9.7085 6.58325 8.66683 6.58325C7.62516 6.58325 6.73975 6.94783 6.01058 7.677C5.28141 8.40617 4.91683 9.29158 4.91683 10.3333C4.91683 11.3749 5.28141 12.2603 6.01058 12.9895C6.73975 13.7187 7.62516 14.0833 8.66683 14.0833ZM8.66683 12.4166C8.0835 12.4166 7.59044 12.2152 7.18766 11.8124C6.78489 11.4096 6.5835 10.9166 6.5835 10.3333C6.5835 9.74992 6.78489 9.25686 7.18766 8.85408C7.59044 8.45131 8.0835 8.24992 8.66683 8.24992C9.25016 8.24992 9.74322 8.45131 10.146 8.85408C10.5488 9.25686 10.7502 9.74992 10.7502 10.3333C10.7502 10.9166 10.5488 11.4096 10.146 11.8124C9.74322 12.2152 9.25016 12.4166 8.66683 12.4166Z"
                  fill="black" />
              </svg>
            </span>
          </label>
          <input accept=".png, .jpg, .jpeg" id="file-upload" type="file" className="hidden" onChange={handleFileUpload} />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <div className="inter-regular-12 flex items-center justify-center">
          Stumbled upon a glitch?
          <br />
          Find a bug?! Let us know! 💖
        </div>
        <div className="py-2 mt-3 px-10"><p className="inter-bold-text-16 decoration-solid underline cursor-pointer" onClick={() => {
          navigate(`${AppPaths.CHAT}?id=${apiData?.contacts?.technicalSupports[0]?._id}&role=${apiData?.contacts?.technicalSupports[0]?.role}`);
        }}>Report an Issue</p></div>
        <SecondaryButton
          onClick={async () => {
            dispatch(setLogout());
          }}
          text="Logout"
          containerStyles="py-2 mt-3 px-10"
          textStyle="inter-bold-text-16"
        />
        {/* <SecondaryButton
          onClick={() => {
            navigate(AppPaths.TECHNICAL_SUPPORT);
          }}
          text="Report an Issue"
          containerStyles="py-2 mt-3 px-10"
          textStyle="inter-bold-text-16"
        /> */}
      </div>
    );
  };

  return (
    <>
      {load ? <div className="flex justify-center items-center h-screen mb-5">
        <img width="100px" className="center" src={LoadImage} alt="loader" />
      </div> : <div className="flex flex-1 flex-col relative pb-20 mb-10">
        {renderTopGradient()}
        {renderProfile()}
        {renderCard()}
        <div className="flex flex-1 items-center justify-center">
          {renderFooter()}
        </div>
      </div>}
    </>
  );
};

export default ProfileDetails;
