import { useNavigate } from "react-router";
import { AppPaths } from "../../../utils/AppPaths";
import { useAppDispatch } from "../../../redux/store";
import { useEffect, useState } from "react";
import { setActiveTab } from "../../../redux/rootSlice";
import ApiService from "../../../api/ApiServices";
import LoadImage from '../../../assets/loader.gif';
import HiIcon from '../../../assets/hi.png';
import { baseURL } from "../../../api/Api";
import Avatar from "react-avatar";

const ChatList = () => {
  const [messages, setMessages] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveTab(2));
  }, [dispatch]);

  const getMessages = async () => {
    setLoad(true)
    const response = await ApiService.getMessages();
    setMessages(response?.data);
    setLoad(false)
  }

  useEffect(() => {
    getMessages();
  }, [])

  const renderHeader = () => {
    return (
      <div className="flex flex-row items-center">
        <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="mr-2 home-frag-pic-backbtn"
            src={require("../../../assets/arrow-left.png")}
          />
        </div>
        <div className="flex items-center justify-center flex-1 btn-onb2-text">
          Messages
        </div>
        {/* <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="mr-2 home-frag-pic-cal"
            src={require("../../../assets/edit_pink.png")}
          />
        </div> */}
      </div>
    );
  };

  const renderProfile = (item: any) => {
    return (
      <div className="flex items-center justify-center flex-1 ">
        <div className="relative chat-icon-cont">
          {item?.profileUrl !== "" ? <img
            src={baseURL + item?.profileUrl}
            alt=""
            className="chat-profile-item"
          /> : <Avatar name={item?.name} size="70" round color={"black"} fgColor={"white"} />}
        </div>
      </div>
    );
  };

  const renderTechnicalSupportProfile = (item: any) => {
    return (
      <div className="flex items-center justify-center flex-1 ">
        <div className="relative chat-icon-cont">
          {item?.role === 'technical-support' ? <img
            src={require("../../../assets/Item1.png")}
            alt=""
          // className="technical-support-item"
          /> : item?.profileUrl !== '' ? <img
            src={item?.profileUrl ? baseURL + item?.profileUrl : require("../../../assets/Item1.png")}
            alt=""
          // className="technical-support-item"
          /> : <Avatar name={item?.name} size="70" round color={"black"} fgColor={"white"} />}
        </div>
      </div>
    );
  }

  const renderListItem = (item: any) => {
    return (
      <div
        className="flex flex-row mb-3 cursor-pointer"
        onClick={() => {
          navigate(`${AppPaths.CHAT}?id=${item?._id}&role=${item?.role}`);
        }}
        style={{ position: 'relative' }}
      >
        <div>{renderProfile(item)}</div>
        <div className="flex flex-col ml-3">
          <div className="inter-text-20-extrabold">{item?.name}</div>
          <div className="inter-regular-14">{item?.role === 'user' ? 'Sister' : 'House Lead'}</div>
          <div
            className="flex flex-row flex-wrap inter-regular-16 chat-message"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item?.message}
          </div>
        </div>
        {!item?.isRead && <div style={{ height: '10px', width: '10px', backgroundColor: '#EA088C', borderRadius: '50%', top: '10px', right: '20px', position: 'absolute' }}>
        </div>}
      </div>
    );
  };

  const renderTechnicalSupportItem = (item: any) => {
    return (
      <div
        className="flex flex-row mb-3 cursor-pointer"
        onClick={() => {
          navigate(`${AppPaths.CHAT}?id=${item?._id}&role=${item?.role}`);
        }}
        style={{ alignItems: 'center', position: 'relative' }}
      >
        <div>{renderTechnicalSupportProfile(item)}</div>
        <div className="flex flex-col ml-3">
          <div className="inter-text-20-extrabold">{item?.name}</div>
          <div className="inter-regular-14">{item?.role === 'user' ? 'Sister' : 'Report technical or general issues'}</div>
          {/* <div
            className="flex flex-row flex-wrap inter-regular-16 chat-message"
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            Where are you from? What's your favorite color?!
          </div> */}
        </div>
        {!item?.isRead && <div style={{ height: '10px', width: '10px', backgroundColor: '#EA088C', borderRadius: '50%', top: '15px', right: '20px', position: 'absolute' }}>
        </div>}
      </div>
    );
  };
  return (
    <>
      {load ? <div className="flex items-center justify-center h-screen mb-5">
        <img width="100px" className="center" src={LoadImage} alt="loader" />
      </div> :
        <div className="relative flex flex-col flex-1 pt-3 mx-3">
          {renderHeader()}
          <div className="my-3 inter-text-20-extrabold">Contacts</div>
          <div className="flex flex-col flex-1">
            {messages?.bigSister?.name && renderListItem(messages?.bigSister)}
            {messages?.houseLead?.name && renderListItem(messages?.houseLead)}
            {/* {renderListItem()} */}
            {messages?.technicalSupports?.map((item: any) => {
              return renderTechnicalSupportItem(item)
            })}
            {messages?.users?.map((item: any) => {
              return renderTechnicalSupportItem(item)
            })}
            <div className="flex justify-end">
              <img src={HiIcon} alt="hi" />
            </div>
          </div>
        </div>}
    </>
  );
};
export default ChatList;
