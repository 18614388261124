import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import ApiService from "../../../api/ApiServices";
import LoadImage from '../../../assets/loader.gif';
import toast from "react-hot-toast";
import { baseURL } from "../../../api/Api";
import Avatar from "react-avatar";

const Chat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const role = queryParams.get("role");
  const senderId = localStorage.getItem('userId');
  const [messages, setMessages] = useState<any>([]);
  const [load, setLoad] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  // const [secondLoad, setSecondLoad] = useState<boolean>(false);
  // const [lock, setLock] = useState<boolean>(false);
  const [input, setInput] = useState<string>("");
  const [userData, setUserData] = useState<any>(null);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const getMessagesData = async () => {
    setLoad(true);
    const response = await ApiService.getMessagesById(id);
    setUserData(response?.user);
    setMessages(response?.data);
    setLoad(false)
  }

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = scrollRef.current ?? {};
    if (scrollTop !== undefined && clientHeight !== undefined && scrollHeight !== undefined) {
      setIsScrolledToBottom(scrollTop + clientHeight === scrollHeight);
    }
  };

  // Automatically scroll to the bottom when messages change and already at bottom
  useEffect(() => {
    if (isScrolledToBottom && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages, isScrolledToBottom]);

  const handleReadMessage = async () => {
    const response = await ApiService.readMessagesById(id);
  }

  useEffect(() => {
    handleReadMessage()
  }, [])

  useEffect(() => {
    if (id) {
      getMessagesData();
      const interval = setInterval(() => {
        getMessagesData();
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [id])
  const renderTopGradient = () => (
    <svg
      // width="375"
      // height="136"
      viewBox="0 0 375 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        top: 80,
        zIndex: -10,
      }}
    >
      <path
        d="M-38 0C133.539 24.1817 229.734 37.9713 396 0V93C218.223 39.9165 123.228 39.5123 -38 93V0Z"
        fill="url(#paint0_linear_1420_5392)"
        fill-opacity="0.8"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1420_5392"
          x1="-7.47784"
          y1="46.3524"
          x2="357.534"
          y2="46.3524"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.119792" stop-color="#FB6ADD" />
          <stop offset="0.260417" stop-color="#FF9D6C" />
          <stop offset="0.416667" stop-color="#FFDC64" />
          <stop offset="0.578125" stop-color="#42C17B" />
          <stop offset="0.75" stop-color="#3AC0E1" />
          <stop offset="0.963542" stop-color="#7595F5" />
        </linearGradient>
      </defs>
    </svg>
  );
  const renderProfile = () => {
    return (
      <div
        className="absolute left-0 right-0 flex flex-row items-center flex-1 mx-3"
        style={{ top: 70 }}
      >
        <img
          src={require("../../../assets/phone.png")}
          alt=""
          className="phone"
        />
        <div className="flex items-center justify-center flex-1 ">
          {role !== 'technical-support' && <div className="relative chat-icon-cont">
            {userData?.profileUrl !== '' ? <img
              src={userData?.profileUrl}
              alt=""
              className="absolute chat-profile-item"
            /> : <Avatar name={userData?.name} size="70" round color={"black"} fgColor={"white"} />}
          </div>}
          {role === 'technical-support' && <div className="relative py-5 chat-icon-cont px-7">
            {<img
              src={require("../../../assets/Item1.png")}
              alt=""
              className="absolute chat-profile-item"
            />}
          </div>}
        </div>
        <img
          src={require("../../../assets/img_envelope.png")}
          alt=""
          className="envelope"
        />
      </div>
    );
  };

  const handleInputChange = (e: any) => {
    setInput(e.target.value)
  }

  const handleSendMessage = async () => {
    if (input) {
      try {
        const response = await ApiService.sendMessage({
          message: input,
          receiverId: id,
          receiverRole: role,
        });
        if (response?.status) {
          setInput('')
          const newMessage = { message: input, senderId: { _id: senderId } };
          if (messages.length === 0) {
            setMessages([newMessage]);
          } else {
            setMessages([...messages, newMessage]);
          }
        }
      } catch (error) {
        console.error("Error while sending message:", error);
      }
    } else {
      toast.error('Please enter message')
    }
  }

  const renderTextInput = () => {
    return (
      <div className="bottom-insets">
        {/* <img
          alt=""
          className="chat-input-icon"
          src={require("../../../assets/camera.png")}
        /> */}
        <div className="flex border chat-Text-Box ">
          {/* <span className="camera mx-[0.8rem] my-[0.7rem]">
            <svg
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.00016 16.9999C1.54183 16.9999 1.14947 16.8367 0.823079 16.5103C0.496691 16.1839 0.333496 15.7916 0.333496 15.3333V5.33325C0.333496 4.87492 0.496691 4.48256 0.823079 4.15617C1.14947 3.82978 1.54183 3.66659 2.00016 3.66659H4.62516L6.16683 1.99992H11.1668V3.66659H6.896L5.37516 5.33325H2.00016V15.3333H15.3335V7.83325H17.0002V15.3333C17.0002 15.7916 16.837 16.1839 16.5106 16.5103C16.1842 16.8367 15.7918 16.9999 15.3335 16.9999H2.00016ZM15.3335 5.33325V3.66659H13.6668V1.99992H15.3335V0.333252H17.0002V1.99992H18.6668V3.66659H17.0002V5.33325H15.3335ZM8.66683 14.0833C9.7085 14.0833 10.5939 13.7187 11.3231 12.9895C12.0522 12.2603 12.4168 11.3749 12.4168 10.3333C12.4168 9.29158 12.0522 8.40617 11.3231 7.677C10.5939 6.94783 9.7085 6.58325 8.66683 6.58325C7.62516 6.58325 6.73975 6.94783 6.01058 7.677C5.28141 8.40617 4.91683 9.29158 4.91683 10.3333C4.91683 11.3749 5.28141 12.2603 6.01058 12.9895C6.73975 13.7187 7.62516 14.0833 8.66683 14.0833ZM8.66683 12.4166C8.0835 12.4166 7.59044 12.2152 7.18766 11.8124C6.78489 11.4096 6.5835 10.9166 6.5835 10.3333C6.5835 9.74992 6.78489 9.25686 7.18766 8.85408C7.59044 8.45131 8.0835 8.24992 8.66683 8.24992C9.25016 8.24992 9.74322 8.45131 10.146 8.85408C10.5488 9.25686 10.7502 9.74992 10.7502 10.3333C10.7502 10.9166 10.5488 11.4096 10.146 11.8124C9.74322 12.2152 9.25016 12.4166 8.66683 12.4166Z"
                fill="#EA088C"
              />
            </svg>
          </span> */}
          <input
            className="grow my-[0.7rem] ml-4"
            type="text"
            name="techSupportInput"
            id="Support-input"
            placeholder="Message..."
            value={input}
            onChange={handleInputChange}
          />
          {/* <span className="audio my-[0.7rem]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1420_5434"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1420_5434)">
                <path
                  d="M12 14C11.1667 14 10.4583 13.7083 9.875 13.125C9.29167 12.5417 9 11.8333 9 11V5C9 4.16667 9.29167 3.45833 9.875 2.875C10.4583 2.29167 11.1667 2 12 2C12.8333 2 13.5417 2.29167 14.125 2.875C14.7083 3.45833 15 4.16667 15 5V11C15 11.8333 14.7083 12.5417 14.125 13.125C13.5417 13.7083 12.8333 14 12 14ZM11 21V17.925C9.26667 17.6917 7.83333 16.9167 6.7 15.6C5.56667 14.2833 5 12.75 5 11H7C7 12.3833 7.4875 13.5625 8.4625 14.5375C9.4375 15.5125 10.6167 16 12 16C13.3833 16 14.5625 15.5125 15.5375 14.5375C16.5125 13.5625 17 12.3833 17 11H19C19 12.75 18.4333 14.2833 17.3 15.6C16.1667 16.9167 14.7333 17.6917 13 17.925V21H11ZM12 12C12.2833 12 12.5208 11.9042 12.7125 11.7125C12.9042 11.5208 13 11.2833 13 11V5C13 4.71667 12.9042 4.47917 12.7125 4.2875C12.5208 4.09583 12.2833 4 12 4C11.7167 4 11.4792 4.09583 11.2875 4.2875C11.0958 4.47917 11 4.71667 11 5V11C11 11.2833 11.0958 11.5208 11.2875 11.7125C11.4792 11.9042 11.7167 12 12 12Z"
                  fill="#EA088C"
                />
              </g>
            </svg>
          </span>
          <span className="image mx-[0.4rem] my-[0.7rem]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1420_5437"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1420_5437)">
                <path
                  d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H14V5H5V19H19V10H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM17 9V7H15V5H17V3H19V5H21V7H19V9H17ZM6 17H18L14.25 12L11.25 16L9 13L6 17Z"
                  fill="#EA088C"
                />
              </g>
            </svg>
          </span> */}
          {/* <span className="emoticons mx-[0.4rem] my-[0.7rem]">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_1420_5440"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_1420_5440)">
                <path
                  d="M12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C12.7167 2 13.4083 2.07083 14.075 2.2125C14.7417 2.35417 15.3833 2.55833 16 2.825V5.075C15.4167 4.74167 14.7875 4.47917 14.1125 4.2875C13.4375 4.09583 12.7333 4 12 4C9.78333 4 7.89583 4.77917 6.3375 6.3375C4.77917 7.89583 4 9.78333 4 12C4 14.2167 4.77917 16.1042 6.3375 17.6625C7.89583 19.2208 9.78333 20 12 20C14.2167 20 16.1042 19.2208 17.6625 17.6625C19.2208 16.1042 20 14.2167 20 12C20 11.4667 19.9458 10.95 19.8375 10.45C19.7292 9.95 19.5833 9.46667 19.4 9H21.55C21.7 9.48333 21.8125 9.97083 21.8875 10.4625C21.9625 10.9542 22 11.4667 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM20 7V5H18V3H20V1H22V3H24V5H22V7H20ZM15.5 11C15.9167 11 16.2708 10.8542 16.5625 10.5625C16.8542 10.2708 17 9.91667 17 9.5C17 9.08333 16.8542 8.72917 16.5625 8.4375C16.2708 8.14583 15.9167 8 15.5 8C15.0833 8 14.7292 8.14583 14.4375 8.4375C14.1458 8.72917 14 9.08333 14 9.5C14 9.91667 14.1458 10.2708 14.4375 10.5625C14.7292 10.8542 15.0833 11 15.5 11ZM8.5 11C8.91667 11 9.27083 10.8542 9.5625 10.5625C9.85417 10.2708 10 9.91667 10 9.5C10 9.08333 9.85417 8.72917 9.5625 8.4375C9.27083 8.14583 8.91667 8 8.5 8C8.08333 8 7.72917 8.14583 7.4375 8.4375C7.14583 8.72917 7 9.08333 7 9.5C7 9.91667 7.14583 10.2708 7.4375 10.5625C7.72917 10.8542 8.08333 11 8.5 11ZM12 17.5C13.1333 17.5 14.1625 17.1792 15.0875 16.5375C16.0125 15.8958 16.6833 15.05 17.1 14H6.9C7.31667 15.05 7.9875 15.8958 8.9125 16.5375C9.8375 17.1792 10.8667 17.5 12 17.5Z"
                  fill="#EA088C"
                />
              </g>
            </svg>
          </span> */}
          <span className="ml-[0.4rem] my-[0.7rem] mr-3 cursor-pointer" onClick={handleSendMessage}>
            <svg
              fill="#EA088C"
              height="17px"
              width="17px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 495.003 495.003"
              xmlSpace="preserve"
            >
              <g id="XMLID_51_">
                <path
                  id="XMLID_53_"
                  d="M164.711,456.687c0,2.966,1.647,5.686,4.266,7.072c2.617,1.385,5.799,1.207,8.245-0.468l55.09-37.616
		l-67.6-32.22V456.687z"
                />
                <path
                  id="XMLID_52_"
                  d="M492.431,32.443c-1.513-1.395-3.466-2.125-5.44-2.125c-1.19,0-2.377,0.264-3.5,0.816L7.905,264.422
		c-4.861,2.389-7.937,7.353-7.904,12.783c0.033,5.423,3.161,10.353,8.057,12.689l125.342,59.724l250.62-205.99L164.455,364.414
		l156.145,74.4c1.918,0.919,4.012,1.376,6.084,1.376c1.768,0,3.519-0.322,5.186-0.977c3.637-1.438,6.527-4.318,7.97-7.956
		L494.436,41.257C495.66,38.188,494.862,34.679,492.431,32.443z"
                />
              </g>
            </svg>
          </span>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="relative flex flex-col justify-between flex-1 h-svh pt-3 h-[90vh]">
        <div className="flex flex-row items-center mx-3">
          <div>
            <img
              onClick={() => {
                navigate(-1);
              }}
              alt=""
              className="mr-2 home-frag-pic-backbtn"
              src={require("../../../assets/arrow-left.png")}
            />
          </div>
          <div className="flex items-center justify-center flex-1 btn-onb2-text">
            {userData?.name}
          </div>
        </div>
        {renderTopGradient()}
        {renderProfile()}
        <div className="flex flex-col items-center justify-center mt-32">
          <div className="inter-semibold-20">{userData?.name}</div>
          {/* <div className="sub-title-text-16">
            Your Big Sister since August 2023
          </div> */}
        </div>
        {/* {load ? <div className="flex items-center justify-center h-500 mb-5">
          <img width="100px" className="center" src={LoadImage} alt="loader" />
        </div> : <> */}
        {messages && messages?.length === 0 && !load ?
          <div className="mt-20 text-center align-items-center">No Messages available</div>
          : <div ref={scrollRef} onScroll={handleScroll} className="flex flex-col flex-1 mt-10 text-sm font-medium px-3 overflow-y-auto chat-scrollbar">
            {messages.map((message: { senderId: { _id: any, profileUrl: any, name: any }; message: string }, index: Key | null | undefined) => (
              <div
                key={index}
                className={`flex flex-row items-end ${message.senderId?._id === senderId
                  ? 'justify-end'
                  : 'justify-start'
                  } mb-3`}
              >
                {role !== 'technical-support' && message.senderId._id !== senderId && (
                  message?.senderId.profileUrl ? <img
                    src={baseURL + message?.senderId.profileUrl}
                    alt=""
                    className="w-8 h-8 rounded-full"
                  /> : <Avatar color={"black"} fgColor={"white"} size="25" name={message?.senderId?.name} className="rounded-full" />
                )}
                {role === 'technical-support' && message.senderId._id !== senderId && <div className="relative message-chat-icon-cont">
                  {<img
                    src={require("../../../assets/Item1.png")}
                    alt=""
                    className="absolute message-chat-profile-item"
                    height="20px"
                    width="20px"
                  />}
                </div>}
                <div className="flex flex-col gap-3 ml-3">
                  <div className={`flex flex-col ${message.senderId?._id !== senderId ? 'items-end' : 'items-start'}`}>
                    <div className={`bg-[#ECECEC] px-3 py-2 rounded-lg max-w-80 ${message.senderId?._id === senderId ? 'bg-[#FFD3F6]' : 'bg-[#ECECEC]'}`}>
                      <p>{message.message}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
        }
        {/* </>} */}
        {renderTextInput()}

      </div>
    </>
  );
};
export default Chat;
