import { useState } from "react";
import TextInput from "../components/TextInput";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'
import ApiService from "../api/ApiServices";
import { useAppDispatch } from "../redux/store";
import { setUserData } from "../redux/rootSlice";
import { isValidPassword } from "../utils/utils";
import moment from "moment";
import { AppPaths } from "../utils/AppPaths";

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams?.get('token');
  const [confirmInputValue, setConfirmInputValue] = useState("");
  const [newInputValue, setNewInputValue] = useState("");
  const navigate = useNavigate();
  const [isConfirmPassword, setIsConfirmPassword] = useState(true);
  const [isNewPassword, setIsNewPassword] = useState(true);
  const [newPasswordValidation, setNewPasswordValidation] = useState<boolean>(false);
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState<boolean>(false);
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
  const handleConfirmInputChange = (e: any) => {
    setConfirmInputValue(e.target.value);
    setConfirmPasswordValidation(!isValidPassword(e.target.value))
    setPasswordsMatch(e.target.value === newInputValue);
  };

  const handleNewInputChange = (e: any) => {
    setNewInputValue(e.target.value);
    setNewPasswordValidation(!isValidPassword(e.target.value))
    setPasswordsMatch(e.target.value === confirmInputValue);
  };

  const onContinue = async () => {
    if (!passwordsMatch) {
      toast.error("New password and confirm password do not match.");
      return;
    }
    try {
      const response = await ApiService.updateUserPassword({
        // email: localStorage.getItem('forgotMail'),
        // id: localStorage.getItem('id'),
        token,
        new_password: newInputValue,
      });
      if (response?.status) {
        navigate(AppPaths.LOGIN);
        localStorage.removeItem('forgotMail');
        localStorage.removeItem('id');
      } else {
        toast.error(response?.message)
      }
    } catch (error) {
      console.error("Error while updating password", error);
    }
  }
  return (
    <div className="flex flex-1 flex-col relative pb-20 mx-1 pt-3 mb-5">
      <div className="flex flex-row mx-3 items-center">
        {/* <div>
          <img
            onClick={() => {
              navigate(-1);
            }}
            alt=""
            className="home-frag-pic-backbtn mr-2"
            src={require("../assets/arrow-left.png")}
          />
        </div> */}
        <div className="btn-onb2-text flex flex-1 items-center justify-center">
          Reset Password
        </div>
      </div>
      <div className="flex flex-col flex-1 mx-3 overflow-y-auto mb-3">

        <span className="">
          <div className="input-text-14-bold mt-4">New Password</div>
          <TextInput
            placeholder="Enter your new password"
            value={newInputValue}
            onChange={handleNewInputChange}
            secure={isNewPassword}
            containerStyles="px-3 py-3 flex flex-1"
            rightIcon={
              <img
                alt=""
                className="Img-24 absolute right-6"
                src={!isNewPassword ? require("../assets/eye_open.png") : require("../assets/eye_close.png")}
                onClick={() => {
                  setIsNewPassword(!isNewPassword);
                }}
              />
            }
            textInputStyles={{ width: '85%' }}
          />
          {newPasswordValidation && <span className="mt-4" style={{ color: 'red' }}>Password is not valid!</span>}
        </span>

        {/* <div className="pt-10">
          <div className="inter-16-bold">Password must:</div>
          <div className="sub-title-text-16 pt-1">
            Be atleast 8 characters in length
          </div>
          <div className="sub-title-text-16 pt-1">
            Contain an uppercase letter
          </div>
          <div className="sub-title-text-16 pt-1">
            Contain a lowercase letter
          </div>
          <div className="sub-title-text-16 pt-1">Contain a digit (0-9)</div>
          <div className="sub-title-text-16 pt-1">Not include spaces</div>
        </div> */}
        <div className="pt-5">
          <div className="inter-regular-14 ">Password must include:</div>
          <ul style={{ listStyleType: "disc" }} className="mx-4">
            <li className="inter-regular-14  pt-1">Be atleast 8 characters in length</li>
            <li className="inter-regular-14  pt-1">Contain an uppercase letter</li>
            <li className="inter-regular-14  pt-1">Contain a lowercase letter</li>
            <li className="inter-regular-14  pt-1">Contain a digit (0-9)</li>
            <li className="inter-regular-14  pt-1">Not include spaces</li>
          </ul>
        </div>

        <span className="">
          <div className="input-text-14-bold mt-4">Confirm New Password</div>
          <TextInput
            placeholder="Reenter your new password"
            value={confirmInputValue}
            onChange={handleConfirmInputChange}
            containerStyles="px-3 py-3 flex flex-1"
            secure={isConfirmPassword}
            rightIcon={
              <img
                alt=""
                className="Img-24 absolute right-6"
                src={!isConfirmPassword ? require("../assets/eye_open.png") : require("../assets/eye_close.png")}
                onClick={() => {
                  setIsConfirmPassword(!isConfirmPassword);
                }}
              />
            }
            textInputStyles={{ width: '85%' }}
          />
          {confirmPasswordValidation && <span className="mt-4" style={{ color: 'red' }}>Password is not valid!</span>}
        </span>
      </div>
      <div className="pt-6 mt-10 mb-1">
        <PrimaryButton
          onClick={onContinue}
          text="SAVE"
          containerStyles="py-2 mx-5"
          textStyle="inter-16-bold"
          disable={!newInputValue || !confirmInputValue || confirmPasswordValidation || newPasswordValidation}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
