import { useEffect } from "react";
import "./styles.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useSpring, animated } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import { setAgeModal, setAnimation, setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import StepFour from "../components/StepFour";
import StepOne from "../components/StepOne";
import StepTwo from "../components/StepTwo";
import StepThree from "../components/StepThree";
import StepFive from "../components/StepFive";
import StepSix from "../components/StepSix";
import StepSeven from "../components/StepSeven";
import StepEight from "../components/StepEight";
import { ReactComponent as StepOneMessage } from "../assets/message_svh_one.svg";
import { ReactComponent as StepTwoMessage } from "../assets/message_svh_two.svg";
import { ReactComponent as StepThreeMessage } from "../assets/message_svh_three.svg";
import { ReactComponent as StepFiveMessage } from "../assets/message_svh_five.svg";
import { ReactComponent as StepSevenMessage } from "../assets/message_svh_seven.svg";
import TypeWriter from "../components/TypeWriter";
import AgeModal from "../components/AgeModal";

const OnBoardingScreen = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step, isPopped, shouldRender, isModalAgeOpen } = useAppSelector(
    (state) => state.root
  );
  const { user } = useAppSelector((state) => state.onBoarding);

  // const [isPopped, setIsPopped] = useState(false);
  // const [shouldRender, setShouldRender] = useState(false);

  const slidein = useSpring({
    from: { y: 500 },
    to: { y: 0 },
  });
  const springProps1 = useSpring({
    from: { marginRight: "20%" },
    to: { marginRight: "0%" },
    // loop: { reverse: true },
    config: { duration: 200 },
  });

  const popAnimation = useSpring({
    transform: isPopped ? "scale(1)" : "scale(0.9)",
    config: {
      duration: 100,
    },
  });

  const slideInAnimation = useSpring({
    opacity: shouldRender ? 1 : 0,
    transform: shouldRender ? "translateY(0%)" : "translateY(30%)",
    // config: {
    //   duration: 0,
    // },
  });

  useEffect(() => {
    if (step > 1) {
      springProps1.marginRight.start({
        from: { marginRight: "20%" },
        to: { marginRight: "0%" },
        // loop: { reverse: true },
        config: { duration: 200 },
      });
      setTimeout(() => {
        dispatch(setAnimation(true));
        slidein.y.start({
          from: { y: 500 },
          to: { y: 0 },
        });
      }, 200);
    } else {
      dispatch(setAnimation(true));
    }
  }, [dispatch, step]);

  const renderTopSvg = () => {
    if (step === 1) {
      return (
        <svg
          viewBox="0 0 375 454"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: "absolute",
            // left: -10,
            // right: -10,
            // top: -32,
            zIndex: -1,
          }}
        >
          <path
            d="M-66 43L44.741 18.2962C142.95 -3.6119 244.864 -2.80936 342.716 20.6427L436 43"
            stroke="url(#paint0_linear_1102_5554)"
            stroke-width="270"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1102_5554"
              x1="16"
              y1="43"
              x2="363"
              y2="43"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FF9EEB" />
              <stop
                offset="0.145833"
                stop-color="#FF9728"
                stop-opacity="0.74"
              />
              <stop
                offset="0.302083"
                stop-color="#FFD203"
                stop-opacity="0.61"
              />
              <stop
                offset="0.510417"
                stop-color="#F1F232"
                stop-opacity="0.48"
              />
              <stop offset="0.65625" stop-color="#06AE56" stop-opacity="0.67" />
              <stop
                offset="0.859375"
                stop-color="#22A094"
                stop-opacity="0.77"
              />
              <stop offset="0.994236" stop-color="#91A8EC" />
            </linearGradient>
          </defs>
        </svg>
      );
    }
    return (
      <svg
        // width="100%"
        // height="210"
        viewBox="0 0 395 454"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          top: -40,
          zIndex: -1,
          // left: -10,
          // right: -10,
          // maxHeight: 320,
          // left: 0,
          // right: 0,
        }}
      >
        <path
          d="M-64 125L27.3314 101.816C137.894 73.7501 253.837 74.7737 363.886 104.787L438 125"
          stroke="#FFD3F6"
          stroke-width="210"
        />
      </svg>
    );
  };

  const renderHeader = () => {
    return (
      <div className="mt-5 mx-5 flex flex-row items-center ">
        <img
          onClick={() => {
            if (step > 1) {
              dispatch(setStep(step - 1));
            } else {
              navigate(AppPaths.SIGNUP);
            }
          }}
          src={require("../assets/arrow-left.png")}
          className="arrow-left mr-2"
          alt=""
        />
        <div className="CountContainer rounded-2xl grid grid-row-1 gap-1 grid-flow-col relative ">
          <div
            // style={springProps2}
            className={`box box-1 flex flex-1  ${
              step < 1 && "bg-white"
            } rounded-l-2xl`}
          />
          <div
            // style={springProps2}
            className={`box box-2 flex flex-1  ${step < 2 && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-3 flex flex-1  ${step < 3 && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-4 flex flex-1  ${step < 4 && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-5 flex flex-1  ${step < 5 && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-6 flex flex-1  ${step < 6 && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-7 flex flex-1  ${step < 7 && "bg-white"}`}
          />
          <div
            // style={springProps2}
            className={`box box-8 flex flex-1 ${
              true && "bg-white"
            }  rounded-r-2xl`}
          />
          {/* 
          <animated.div
            // style={springProps2}
            style={{ left: `${leftValue}vh` }}
            className="box absolute right-0 rounded-r-2xl bg-black"
          ></animated.div> */}
        </div>
      </div>
    );
  };

  const renderHeaderImage = () => {
    if (step === 1) {
      return (
        <span className="flex flex-1 justify-center relative">
          <img
            alt=""
            src={require("../assets/Item1.png")}
            className="onb1-2 inline-block mr-[-10px] mt-2"
          />
          <span className=" flex justify-center relative">
            <animated.div
              className="relative"
              style={{ ...springProps1, ...popAnimation }}
            >
              <div className="stepOnetext">
                <TypeWriter
                  key={step}
                  text="U4U is your home away from home."
                  text2="What’s your dream home?"
                  delay={40}
                />
              </div>
              <StepOneMessage />
            </animated.div>
          </span>
        </span>
      );
    }
    if (step === 2) {
      return (
        <span className="flex flex-1 justify-center relative">
          <img
            alt=""
            src={require("../assets/Item2.png")}
            className="onb1-2-h inline-block mr-[-10px]"
          />
          <span className=" flex justify-center relative">
            <animated.div
              className="relative"
              style={{ ...springProps1, ...popAnimation }}
            >
              <div className="stepOnetext">
                <TypeWriter
                  key={step}
                  text="At U4U, we party, have fun & make friends!"
                  text2="What party excites you the most?"
                  delay={40}
                />
              </div>
              <StepTwoMessage />
            </animated.div>
          </span>
        </span>
      );
    }
    if (step === 3) {
      return (
        <span className="flex flex-1 justify-center relative">
          <img
            alt=""
            src={require("../assets/Item3.png")}
            className="onb1-3 inline-block mr-[-10px] mt-3"
          />
          <span className=" flex justify-center relative">
            <animated.div
              className="relative"
              style={{ ...springProps1, ...popAnimation }}
            >
              <div className="stepOnetext">
                <TypeWriter
                  key={step}
                  text="With us, you journey at your own pace with activities tailored to U."
                  text2="What shoes do you journey through life in?"
                  delay={40}
                />
              </div>
              <StepThreeMessage />
            </animated.div>
          </span>
        </span>
      );
    }
    if (step === 4) {
      return (
        <span className="flex flex-1 justify-center relative">
          <img
            alt=""
            src={require("../assets/Item4.png")}
            className="onb1-4 inline-block mr-[-10px] mt-2"
          />
          <span className=" flex justify-center relative">
            <animated.div
              className="relative"
              style={{ ...springProps1, ...popAnimation }}
            >
              <div className="stepOnetext">
                <TypeWriter
                  key={step}
                  text="Teen girls & demigirls 💖 U4U for many reasons!"
                  text2="What would you love from U4U?"
                  delay={40}
                />
              </div>
              <StepTwoMessage />
            </animated.div>
          </span>
        </span>
      );
    }
    if (step === 5) {
      return (
        <span className="flex flex-1 justify-center relative">
          <img
            alt=""
            src={require("../assets/item5.png")}
            className="onb1-4 inline-block mr-[-1px] mt-1 "
          />
          <span className=" flex justify-center relative">
            <animated.div
              className="relative"
              style={{ ...springProps1, ...popAnimation }}
            >
              <div className="stepOnetext">
                <TypeWriter
                  key={step}
                  text="U4U is made up of houses with girls of a similar age."
                  text2="What grade are/will you be in?"
                  delay={40}
                />
              </div>
              <StepFiveMessage />
            </animated.div>
          </span>
        </span>
      );
    }
    if (step === 6) {
      return (
        <span className="flex flex-1 justify-center relative">
          <img
            alt=""
            src={require("../assets/item6.png")}
            className="onb1-4 inline-block mr-[-1px] mt-1"
          />
          <span className=" flex justify-center relative">
            <animated.div
              className="relative"
              style={{ ...springProps1, ...popAnimation }}
            >
              <div className="stepOnetext">
                <TypeWriter
                  key={step}
                  text="Your U4U house will have girls from all across the United States."
                  text2="Where are you from?"
                  delay={40}
                />
              </div>
              <StepFiveMessage />
            </animated.div>
          </span>
        </span>
      );
    }
    if (step === 7) {
      return (
        <span className="flex flex-1 justify-center relative">
          <img
            alt=""
            src={require("../assets/Item1.png")}
            className="onb1-2 inline-block mr-[-10px]"
          />
          <span className=" flex justify-center relative">
            <animated.div
              className="relative"
              style={{ ...springProps1, ...popAnimation }}
            >
              <div className="stepOnetext">
                <TypeWriter
                  key={step}
                  text="We can’t wait to know you better and match you to a U4U house."
                  text2="Tell us the best way to stay in touch with you!"
                  delay={40}
                />
              </div>
              <StepSevenMessage />
            </animated.div>
          </span>
        </span>
      );
    }
    if (step === 8) {
      return (
        <span className="flex flex-1 justify-center relative">
          <img
            alt=""
            src={require("../assets/Item1.png")}
            className="onb1-2 inline-block mr-[-10px]"
          />
          <span className=" flex justify-center relative">
            <animated.div
              className="relative"
              style={{ ...springProps1, ...popAnimation }}
            >
              <div className="stepOnetext">
                <TypeWriter
                  key={step}
                  text="One last step to get the key to unlock your house. "
                  text2="Create a username and password!"
                  delay={40}
                />
              </div>
              <StepFiveMessage />
            </animated.div>
          </span>
        </span>
      );
    }
  };

  return (
    <div className="flex flex-col h-svh relative bg-white z-10">
      {renderTopSvg()}
      <div className="absolute">
        <AgeModal
          visible={isModalAgeOpen}
          setVisible={(val) => dispatch(setAgeModal(val))}
        />
      </div>
      <div className="z-11">
        {renderHeader()}
        <div className="mt-3 mx-3">{renderHeaderImage()}</div>
      </div>
      <animated.div
        className="flex flex-col justify-center flex-1 py-4 overflow-y-auto "
        style={{ ...slideInAnimation, ...slidein }}
      >
        {step === 1 && shouldRender && <StepOne />}
        {step === 2 && shouldRender && <StepTwo />}
        {step === 3 && shouldRender && <StepThree />}
        {step === 4 && shouldRender && <StepFour />}
        {step === 5 && shouldRender && <StepFive />}
        {step === 6 && shouldRender && <StepSix />}
        {step === 7 && shouldRender && <StepSeven />}
        {step === 8 && shouldRender && <StepEight />}
      </animated.div>
    </div>
  );
};

export default OnBoardingScreen;
