import React from "react";
import "./styles.css";
import SecondaryButton from "./SecondaryButton";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import { setUser } from "../redux/OnBoardingSlice";

const StepTwo = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);

  const onContinue = (house = "2") => {
    dispatch(setUser({ step2: house }));
    navigate(AppPaths.ONBOARDING_THREE);
  };

  const options = [
    { text: "House party", icon: "Houseparty.png" },
    { text: "Sleepover", icon: "sleepover.png" },
    { text: "Game night", icon: "Gamenight.png" },
    { text: "Clubbing", icon: "clubbing.png" },
    { text: "Other", icon: "celebration.png" },
  ];

  return (
    <>
      {/* <div className="flex item-center justify-center">
        <span className="title-text ">What party excites you the most?</span>
      </div> */}

      <div className="mx-10 flex flex-col flex-1 ">
        {options.map((option, index) => (
          <SecondaryButton
            key={index}
            onClick={() => onContinue(option.text)}
            text={option.text}
            icon={
              <img
                src={require(`../assets/${option.icon}`)}
                className="btn-icon"
                alt=""
              />
            }
            selected={user?.step2 === option.text}
            containerStyles={`btn-onb2  ${index !== 0 ? "mt-4" : "pt-1"}`}
            textStyle="btn-onb2-text"
          />
        ))}
      </div>
    </>
  );
};

export default StepTwo;
