import React from "react";
import "./styles.css";
import SecondaryButton from "./SecondaryButton";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import { setUser } from "../redux/OnBoardingSlice";

const StepOne = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);

  const onContinue = (house = "1") => {
    dispatch(setUser({ step1: house }));
    navigate(AppPaths.ONBOARDING_TWO);
  };

  const options = [
    { text: "Beach house", icon: "beach-house.png" },
    { text: "Cabin", icon: "Cabin.png" },
    { text: "Penthouse", icon: "Penthouse.png" },
    { text: "Castle", icon: "Castle.png" },
    { text: "Other", icon: "other.png" },
  ];

  return (
    <>
      {/* <div className="flex item-center justify-center">
        <span className="title-text ">What’s your dream home?</span>
      </div> */}

      <div className="mx-10 flex flex-col flex-1">
        {options.map((option, index) => (
          <SecondaryButton
            key={index} // Provide a unique key for each button
            onClick={() => onContinue(option.text)}
            text={option.text}
            icon={
              <img
                src={require(`../assets/${option.icon}`)}
                className="btn-icon"
                alt=""
              />
            }
            selected={user?.step1 === option.text}
            containerStyles={`btn-onb2 ${index !== 0 ? "mt-4" : "pt-1"}`}
            textStyle="btn-onb2-text"
          />
        ))}
        <div className="flex justify-center btn-onb2-text-1 mb-3 mt-4">
          <span className="sub-title-text-16">
            Already have an account?&nbsp;
          </span>
          <span
            className="title-text-16 underline underline-offset-4"
            onClick={() => {
              navigate(AppPaths.LOGIN);
            }}
          >
            Log In
          </span>
        </div>
      </div>
    </>
  );
};

export default StepOne;
