import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../../components/SecondaryButton";
import MoodRingModal from "../../../components/moodring/MoodRingModal";
import ApiService from "../../../api/ApiServices";
import moment from "moment";
import LoadImage from '../../../assets/loader.gif';
import { getImageUrl } from "../../../constants/reactionImages";
import { baseURL } from "../../../api/Api";
const MoodRing = () => {
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [moodRingData, setMoodRingData] = useState<any>(null);
  const [currentData, setCurrentData] = useState<any>(null);
  const [dailyData, setDailyData] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);
  const [currentPeriod, setCurrentPeriod] = useState<any>(null);
  const [currentImage, setCurrentImage] = useState<any>(null);
  const [info, setInfo] = useState<any>(null);
  const getMoodRingData = async () => {
    setLoad(true)
    let month = new Date().getMonth() + 1
    let monthString: string;
    if (month < 10) {
      monthString = "0" + month;
    } else {
      monthString = month.toString();
    }
    const response = await ApiService.getMoodRingData(monthString);
    setMoodRingData(response?.data);
    setCurrentData(response?.data?.currentMoodRings[0]?.viewSummary)
    setDailyData(response?.data?.currentMoodRings[0]?.dailyCheckIns)
    setCurrentPeriod(response?.data?.currentMoodRings[0]?.period)
    setCurrentImage(response?.data?.currentMoodRings[0]?.ringImage)
    setLoad(false)
  }


  useEffect(() => {
    getMoodRingData()
  }, [])

  const rendersvg1 = () => {
    return (
      <svg
        // width="339"
        // height="42"
        viewBox="0 0 339 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          zIndex: -1,
          top: 20,
          width: '100%',
        }}
      >
        <path
          d="M0 0C133.99 10.9207 209.129 17.1483 339 0V42C200.138 18.0268 125.936 17.8443 0 42V0Z"
          fill="url(#paint0_linear_1153_1949)"
          fill-opacity="0.8"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1153_1949"
            x1="23.841"
            y1="20.9333"
            x2="308.954"
            y2="20.9333"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E8A4E0" />
            <stop offset="0.197917" stop-color="#ECC542" />
            <stop offset="0.416667" stop-color="#F5F552" />
            <stop offset="0.578125" stop-color="#B6EC80" />
            <stop offset="0.75" stop-color="#69E2E3" />
            <stop offset="0.979167" stop-color="#C0A4EE" />
          </linearGradient>
        </defs>
      </svg>
    );
  };
  const renderCardImage = () => {
    return (
      <div className="z-10 flex justify-center">
        <img
          alt=""
          className="moodring-card-image"
          src={moodRingData?.currentMoodRings.length > 0 ? baseURL + moodRingData?.currentMoodRings[0]?.ringImage : require("../../../assets/moodring.png")}
        />
      </div>
    );
  };
  const renderMoodRingCard = () => {
    return (
      <div className="moodring-card px-2 py-2 relative z-20 mt-3">
        {rendersvg1()}
        {renderCardImage()}
        <div className="flex items-center flex-col">
          <div className="title-text-16">
            {moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('MMMM') === moment(moodRingData?.currentMoodRings[0]?.period.endDate).format('MMMM') ? <div className="inter-24-bold">
              {moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('MMMM')}&nbsp;
              {moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('D')} -&nbsp;
              {moment(moodRingData?.currentMoodRings[0]?.period.endDate).format('D')}
              {/*{moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('DD')}<sup>th</sup> -&nbsp;
              {moment(moodRingData?.currentMoodRings[0]?.period.endDate).format('DD')}<sup>th</sup> */}
            </div> : <div className="title-text-16">
              {moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('MMMM')}&nbsp;
              {moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('D')}
              {/* {moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('DD')}<sup>th</sup> */}
              {moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('MMMM') !== moment(moodRingData?.currentMoodRings[0]?.period.endDate).format('MMMM') &&
                ` - `}
              {moment(moodRingData?.currentMoodRings[0]?.period.endDate).format('MMMM') !== moment(moodRingData?.currentMoodRings[0]?.period.startDate).format('MMMM') &&
                `${moment(moodRingData?.currentMoodRings[0]?.period.endDate).format('MMMM')} `}
              {/* {moment(moodRingData?.currentMoodRings[0]?.period.endDate).format('DD')}<sup>th</sup></div>} */}
              {moment(moodRingData?.currentMoodRings[0]?.period.endDate).format('D')}</div>}
          </div>
          <div className="inter-regular-14 text-center" style={{ padding: '0px 25px'}}>
            {moodRingData?.currentMoodRings[0]?.info}
          </div>
          <SecondaryButton
            text="View Summary"
            containerStyles="px-3 py-2 mt-2"
            textStyle="inter-text-14-semibold"
            onClick={() => {
              setVisible(true);
              setInfo(moodRingData?.currentMoodRings[0]?.info)
              setCurrentData(moodRingData?.currentMoodRings[0]?.viewSummary)
              setDailyData(moodRingData?.currentMoodRings[0]?.dailyCheckIns)
              setCurrentPeriod(moodRingData?.currentMoodRings[0]?.period)
              setCurrentImage(moodRingData?.currentMoodRings[0]?.ringImage)
            }}
          />
        </div>
      </div>
    );
  };

  const getColor = (id: string, type: any) => {
    if (id === "") {
      return '#ECECE9'
    } else {
      if (type === 'Good') {
        return '#FAEDF9'
      } else if (type === 'In-Between') {
        return '#E1F9F9'
      } else if (type === 'Difficult') {
        return '#FBF3D9'
      } else {
        return '#F0FBE6'
      }
    }
  }
  const renderCardDetails = () => {
    return (
      <div className="flex flex-col mt-3">
        <div className="flex flex-row inter-semibold-20 justify-center my-3">
          {moodRingData?.currentMoodRings[0]?.periodMonth}
        </div>
        {moodRingData?.currentMoodRings[0]?.dailyCheckIns?.length === 0 ? <div className="text-center">No Daily Check Ins</div> : <div className="grid grid-cols-7 gap-1 mx-auto">
          {moodRingData?.currentMoodRings[0]?.dailyCheckIns?.map((item: any, index: Key | null | undefined) => {
            return (
              <div
                key={index}
                className={`flex flex-col items-center px-2 py-2 mood-imoji-cont`}
                style={{ minHeight: '116px', minWidth: '45px', backgroundColor: `${getColor(item?._id, item?.emojiType)}` }}
              >
                <div className="inter-12-bold">{moment(item?.date).format('DD')}</div>
                {item?._id !== "" && <><img
                  alt=""
                  className="mood-ring-imoji my-2"
                  src={getImageUrl(item?.todayFeeling) || require("../../../assets/reaction/happy.png")} /><img
                    alt=""
                    className="mood-ring-zzz"
                    src={require("../../../assets/moodringZZZ.png")} /><div className="inter-regular-12">{item?.timeDiff?.hours}.{item?.timeDiff?.minutes} hrs</div></>}
              </div>
            );
          })}
        </div>}
      </div>
    );
  };

  const renderCardList = () => {
    return (
      <div className="flex flex-col mt-3">
        <div className="absolute">
          {visible && <MoodRingModal info={info} ringImg={currentImage} period={currentPeriod} setVisible={setVisible} visible={visible} data={currentData} />}
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row inter-semibold-20  my-2">
            Past mood rings
          </div>
          {/* <img
            alt=""
            className="home-frag-pic-cal mr-2"
            src={require("../../../assets/filter_list.png")}
          /> */}
        </div>
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row inter-text-18-semibold">{new Date().getFullYear()}</div>
        </div>
        <div className="flex flex-col">
          {moodRingData?.pastMoodRings?.map((value: {
            [x: string]: any;
            averageSleepDurationHours: ReactNode; totalDaysCheckedIn: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined;
          }) => {
            return (
              <div className="mood-list-cont flex flex-row py-3 px-3 mt-2 cursor-pointer" onClick={() => {
                setVisible(true)
                setCurrentData(value?.viewSummary)
                setDailyData(value?.dailyCheckIns)
                setCurrentPeriod(value?.period)
                setInfo(value?.info)
                setCurrentImage(value?.ringImage)
              }}>
                <div className="flex flex-1 items-center">
                  <img
                    alt=""
                    className="list-jem"
                    src={value?.ringImage !== "" ? baseURL + value?.ringImage : require("../../../assets/moodring.png")}
                  />
                  <div className="title-text-16 ml-2">{moment(value?.period?.startDate).format('MM/DD')}-{moment(value?.period?.endDate).format('MM/DD')}</div>
                </div>
                <div className="flex flex-1 items-center">
                  <div className="flex flex-1 flex-col items-center">
                    <img
                      alt=""
                      className="mood-ring-zzz"
                      src={require("../../../assets/moodringZZZ.png")}
                    />
                    <div className="inter-text-12-semibold text-center">
                      {value?.averageSleepDurationHours} hrs
                      <br />
                      avg. sleep
                    </div>
                  </div>
                  <div className="flex flex-1 flex-col items-center">
                    <img
                      alt=""
                      className="mood-ring-zzz"
                      src={require("../../../assets/moodring_check.png")}
                    />
                    <div className="inter-text-12-semibold text-center">
                      {value?.totalDaysCheckedIn}/15 days <br />
                      tracked
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      {load ? <div className="flex justify-center items-center h-screen mb-5">
        <img width="100px" className="center" src={LoadImage} alt="loader" />
      </div> : <div className="flex flex-1 flex-col relative pb-20 mx-1 pt-3 mb-5">
        <div className="flex flex-row mx-3 items-center">
          <div>
            <img
              onClick={() => {
                navigate(-1);
              }}
              alt=""
              className="home-frag-pic-backbtn mr-2"
              src={require("../../../assets/arrow-left.png")}
            />
          </div>
          <div className="btn-onb2-text flex flex-1 items-center justify-center">
            Mood Ring
          </div>
          {/* <div>
            <img
              onClick={() => {
                navigate(-1);
              }}
              alt=""
              className="home-frag-pic-cal mr-2"
              src={require("../../../assets/calendar_today.png")}
            />
          </div> */}
        </div>
        <div className="flex flex-col flex-1 mx-3 overflow-y-auto">
          {renderMoodRingCard()}
          {renderCardDetails()}
          {renderCardList()}
        </div>
      </div>}
    </>
  );
};

export default MoodRing;
