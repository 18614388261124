import React from "react";
import "./styles.css";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { setAgeModal, setStep } from "../redux/rootSlice";
import { AppPaths } from "../utils/AppPaths";
import Table from "./Table";
import { setUser } from "../redux/OnBoardingSlice";

const StepFive = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { step } = useAppSelector((state) => state.root);
  const { user } = useAppSelector((state) => state.onBoarding);

  const onContinue = () => {
    navigate(AppPaths.ONBOARDING_SIX);
  };

  const TabelData = [
    "6th Grade",
    "7th Grade",
    "8th Grade",
    "9th Grade / Freshman",
    "10th Grade / Sophomore",
    "11th Grade / Junior",
    "12th Grade / Senior",
  ];
  return (
    <div className="flex flex-1 flex-col relative">
      <div className="flex item-center justify-center">
        <span className="sub-title-text-12 mb-2">
          (This will help us find the perfect house for you.)
        </span>
      </div>
      <div className="mt-3 flex flex-col flex-1 mx-4">
        <Table
          data={TabelData}
          onSelect={(index: number) => {
            dispatch(setUser({ ...user, step5: TabelData[index] }));
            if (index <= 1) {
              dispatch(setAgeModal(true));
            } else {
              onContinue();
            }
          }}
          selected={user?.step5 ? TabelData.indexOf(user?.step5) : -1}
        />
      </div>
    </div>
  );
};

export default StepFive;
