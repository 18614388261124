export const generateDateArray = (num = 10) => {
  const months = [];
  for (let i = 1; i <= num; i++) {
    const month = {
      value: i.toString(),
      label: i.toString(),
    };
    months.push(month);
  }
  return months;
};
export const generateMonthArray = (num: Array<string> = []) => {
  const months = [];
  for (let i = 0; i < num.length; i++) {
    const month = {
      value: num[i],
      label: num[i],
    };
    months.push(month);
  }
  return months;
};

export const generateAgeArray = (num = 18) => {
  const currentYear = new Date().getFullYear();
  const ages = [];

  for (let i = 1; i <= num; i++) {
    const age = {
      value: (currentYear - i).toString(),
      label: (currentYear - i).toString(),
    };
    ages.push(age);
  }

  return ages;
};

export const isValidEmail = (email: string) => {
  // Regular expression for basic email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const isValidPhoneNumber = (phoneNumber: any) => {
  const phoneNumberRegex = /^\d{9,}$/;
  return phoneNumberRegex.test(phoneNumber);
}

export const isValidPassword = (password: any) => {
  const specialCharRegex = /[!@#$%^&*]/;
  const numberRegex = /[0-9]/;
  const capitalLetterRegex = /[A-Z]/;

  const hasSpecialChar = specialCharRegex.test(password);
  const hasNumber = numberRegex.test(password);
  const hasCapitalLetter = capitalLetterRegex.test(password);

  return hasSpecialChar && hasNumber && hasCapitalLetter;
}

export function convertTo24Hour(time: { split: (arg0: string) => [any, any]; }) {
  let [hours, minutesPart] = time.split(':');
  let minutes = minutesPart.slice(0, 2);
  let period = minutesPart.slice(3);
  
  hours = parseInt(hours, 10);
  
  if (period === 'PM' && hours !== 12) {
      hours += 12;
  } else if (period === 'AM' && hours === 12) {
      hours = 0;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes}`;
}

