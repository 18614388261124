/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from "react";
import "../styles.css"; // Import your styling file
import { useNavigate } from "react-router-dom";
import { setActiveTab, setUserData } from "../../redux/rootSlice";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { baseURL } from "../../api/Api";
import ApiService from "../../api/ApiServices";
import Avatar from "react-avatar";

const BottomTabs = ({
  tabs,
  // onTabChange,
}: {
  tabs: Array<{
    path: string;
    id: number;
    url: string;
    selectedUrl?: string,
  }>;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userDetails = useAppSelector<any>(state => state.root.userData);
  const { selectedTab } = useAppSelector((state) => state.root);
  const [focusTab, setFocusTab] = useState(3);
  const [load, setLoad] = useState<boolean>(false);
  const [hasUnreadMessages, setHasUnreadMessages] = useState<boolean>(false);
  const workerRef = useRef<Worker | null>(null);
  const handleTabClick = (tab: any) => {
    dispatch(setActiveTab(tab.id));
    setFocusTab(tab.id)
    navigate(tab.path);
  };

  useEffect(() => {
    const currentTab = tabs.find((tab) => tab.path === location.pathname);
    if (currentTab) {
      setFocusTab(currentTab.id);
    }
  }, [location.pathname, tabs]);

  const getProfileData = async () => {
    setLoad(true);
    const response = await ApiService.getProfile();
    dispatch(setUserData(response?.data?.user))
    setLoad(false)
  }

  useEffect(() => {
    if (userDetails === null) {
      getProfileData()
    }
  }, [])

  // useEffect(() => {
  //   workerRef.current = new Worker(new URL('./messageChecker.worker.js', import.meta.url));
  //   workerRef.current.postMessage('start');
  //   workerRef.current.onmessage = (e) => {
  //     if (e.data.status && e.data.data) {
  //       setHasUnreadMessages(true);
  //     } else {
  //       setHasUnreadMessages(false);
  //     }
  //   };

  //   return () => {
  //     workerRef.current?.terminate();
  //   };
  // }, []);
  const checkMessageStatus = async () => {
    try {
      const response = await ApiService.checkMessage();
      setHasUnreadMessages(response.data);
    } catch (error) {
      console.error('Error checking message status:', error);
    }
  };
  useEffect(() => {
    checkMessageStatus();

    const intervalId = setInterval(checkMessageStatus, 5000);

    return () => clearInterval(intervalId);
  }, [checkMessageStatus]);
  return (
    <div className="BottomTabsContainer">
      <div className="BottomTabsContent">
        {tabs.map((tab: any, index) => (
          <div
            key={tab.id}
            className={`BottomTab ${focusTab === tab.id ? "active magnify" : ""
              }`}
            onClick={() => handleTabClick(tab)}
          >
            {index === 0 ? (
              <div className="mr-2" style={{ border: `${focusTab === tab.id ? "2px solid black" : `${userDetails?.profileUrl ? 'none' : '1px solid black' }`}`, borderRadius: '50%' }}>
            {userDetails?.profileUrl ? <img
              alt=""
              src={!load && userDetails?.profileUrl && baseURL + userDetails?.profileUrl}
              className={`user-cardheader h-8 w-8  ${focusTab === tab.id ? "h-11" : ""
                } `}
                /> : <Avatar name="m e" size="26" round color={focusTab === tab.id ? "black" : "white"} fgColor={focusTab === tab.id ? "white" : "black"} style={{textTransform: 'lowercase'}} />}
          </div>
        ) : (
        <img
          src={
            focusTab === tab.id
              ? tab.selectedUrl || tab.url
              : tab.id === 2 && hasUnreadMessages
              ? tab.alertUrl || tab.url
              : tab.url
          }
          alt=""
          className={`h-8 w-8  ${focusTab === tab.id ? "h-11" : ""}`}
        />
            )}
      </div>
        ))}
    </div>
    </div >
  );
};

export default BottomTabs;
