import React from "react";
import { Route, Routes } from "react-router-dom";
import { AppPaths } from "../utils/AppPaths";
import WelcomeScreen from "../container/WelcomeScreen";
import OnBoardingScreen from "../container/OnBoardingScreen";
import EmailVerification from "../container/EmailVerification";
import LoginScreen from "../container/LoginScreen";
import OnBoardingOne from "../container/OnBoardingOne";
import OnBoardingTwo from "../container/OnBoardingTwo";
import OnBoardingThree from "../container/OnBoardingThree";
import OnBoardingFour from "../container/OnBoardingFour";
import OnBoardingFive from "../container/OnBoardingFive";
import OnBoardingSix from "../container/OnBoardingSix";
import OnBoardingSeven from "../container/OnBoardingSeven";
import OnBoardingEight from "../container/OnBoardingEight";
import ResetPassword from "../container/ResetPassword";
import ForgotPassword from "../container/Forgotpassword";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path={"/"} element={<WelcomeScreen />} />
      <Route path={AppPaths.LOGIN} element={<LoginScreen />} />
      <Route path={AppPaths.ONBOARDING_ONE} element={<OnBoardingOne />} />
      <Route path={AppPaths.ONBOARDING_TWO} element={<OnBoardingTwo />} />
      <Route path={AppPaths.ONBOARDING_THREE} element={<OnBoardingThree />} />
      <Route path={AppPaths.ONBOARDING_FOUR} element={<OnBoardingFour />} />
      <Route path={AppPaths.ONBOARDING_FIVE} element={<OnBoardingFive />} />
      <Route path={AppPaths.ONBOARDING_SIX} element={<OnBoardingSix />} />
      <Route path={AppPaths.ONBOARDING_SEVEN} element={<OnBoardingSeven />} />
      <Route path={AppPaths.ONBOARDING_EIGHT} element={<OnBoardingEight />} />
      <Route path={AppPaths.ONBOARDING} element={<OnBoardingScreen />} />
      <Route path={AppPaths.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={AppPaths.RESET_PASSWORD} element={<ResetPassword />} />
      <Route
        path={AppPaths.EMAILVERIFICARION}
        element={<EmailVerification />}
      />
      <Route path={"*"} element={<WelcomeScreen />} />
    </Routes>
  );
};

export default AuthRoutes;
