import React, { useEffect, useState } from "react";
import "./styles.css";
import { useSpring, animated } from "@react-spring/web";
import { useNavigate } from "react-router-dom";
import { AppPaths } from "../utils/AppPaths";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
const WelcomeScreen = () => {
  const navigate = useNavigate();
  const [isPopped, setIsPopped] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the pop animation on component mount
    setIsPopped(true);
    setIsVisible(true);
  }, []);

  const popAnimation = useSpring({
    transform: isPopped ? "scale(1)" : "scale(0.1)",
  });

  const fadeInAnimation = useSpring({
    opacity: isVisible ? 1 : 0,
    config: { duration: 1000 },
  });

  const springsNewHear = useSpring({
    from: { x: 80 },
    to: { x: -30 },
    delay: 500,
  });
  const springsAlready = useSpring({
    from: { x: -80 },
    to: { x: 20 },
    delay: 700,
  });
  const renderImages = () => {
    return (
      <>
        <animated.img
          style={{ ...fadeInAnimation }}
          src={require("../assets/onbHeart.png")}
          alt=""
          className="absolute onbHeart"
        />
        <animated.img
          style={{ ...fadeInAnimation }}
          src={require("../assets/flower_stem.png")}
          alt=""
          className="absolute flower_stem"
        />
        <animated.img
          style={{ ...fadeInAnimation }}
          src={require("../assets/flower_doodle.png")}
          alt=""
          className="absolute flower_doodle"
        />
        <animated.img
          style={{ ...fadeInAnimation }}
          src={require("../assets/ob1_tree.png")}
          alt=""
          className="absolute ob1_tree"
        />
      </>
    );
  };
  return (
    <div className="flex flex-col h-svh px-5  overflow-hidden">
      {/* <div className="ButtonContainer"> */}
      {renderImages()}

      <div className="flex flex-col flex-1">
        <div className="onb1Container">
          <div className="pt-4">
            <animated.img
              style={{ ...fadeInAnimation }}
              alt=""
              src={require("../assets/Item1.png")}
              className="onb1"
            />
          </div>
          <animated.img
            style={{ ...popAnimation }}
            alt=""
            src={require("../assets/u4uOnb.png")}
            className="u4uOnb mt-2"
          />
        </div>

        <div className="onb1-text py-5 text-center ">
          Where you get to be <br /> uniquely you.
        </div>
      </div>

      <div className="px-10 relative mid-container ">
        <animated.img
          src={require("../assets/ob1_arr1.png")}
          alt=""
          className="absolute ob1_arr1"
          style={{ ...springsNewHear }}
        />
        <animated.div
          style={{ ...springsNewHear }}
          className="absolute ob1_arr1_text"
        >
          <div className="ob1_arr1_text-anim">
            New
            <br /> here?
          </div>
        </animated.div>

        <animated.img
          src={require("../assets/ob1_arrR.png")}
          alt=""
          className="absolute ob1_arrR"
          style={{ ...springsAlready }}
        />
        <animated.div
          style={{ ...springsAlready }}
          className="absolute ob1_arr2_text"
        >
          <div className="ob1_arr2_text_anim">
            Already on <br />
            U4U?
          </div>
        </animated.div>
        <animated.div style={{ ...fadeInAnimation }} className="pt-5 mx-4">
          <PrimaryButton
            onClick={() => {
              navigate(AppPaths.ONBOARDING_ONE);
            }}
            text="CHECK IT OUT"
            containerStyles="py-4"
          />
        </animated.div>
        <animated.div style={{ ...fadeInAnimation }} className="pt-5 mx-4">
          <SecondaryButton
            text={"UNLOCK MY DOOR"}
            onClick={() => {
              navigate(AppPaths.LOGIN);
            }}
            containerStyles="py-4"
            textStyle="secondary-btn-text"
          />
        </animated.div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
