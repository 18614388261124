import React from "react";
import "./styles.css";
import { ReactComponent as Heart } from "../assets/heart-white.svg";
import { useSpring, animated } from "@react-spring/web";

const SecondaryButton = ({
  text,
  onClick,
  icon = null,
  containerStyles = "",
  iconStyles = "",
  textStyle = "",
  selected = false,
}: {
  text?: string;
  onClick?: () => void;
  icon?: any;
  containerStyles?: string;
  textStyle?: string;
  iconStyles?: string;
  selected?: boolean;
}) => {
  const [springProps, set] = useSpring(() => ({
    translateY: 0,
    config: { tension: 300, friction: 20 },
  }));

  const handleMouseEnter = () => {
    set({ translateY: -5 });
  };

  const handleMouseLeave = () => {
    set({ translateY: 0 });
  };

  return (
    <animated.div
      className={`relative secondary-btn flex  ${containerStyles}`}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
      style={{
        transform: springProps.translateY.interpolate(
          (ty) => `translateY(${ty}px)`
        ),
        backgroundColor: selected ? "black" : "white",
        boxShadow: selected ? "none" : "",
      }}
    >
      {icon && (
        <div className={`absolute secondary-btn-icon  ${iconStyles}`}>
          {icon}
        </div>
      )}
      {text && (
        <div className={selected ? "inter-20-bold font-white" : `${textStyle}`}>
          {text || ""}
        </div>
      )}
      {selected && (
        <div className={`absolute right-6 ${iconStyles}`}>
          <Heart />
        </div>
      )}
    </animated.div>
  );
};

export default SecondaryButton;
