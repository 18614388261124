import {
  Action,
  AnyAction,
  configureStore,
  ThunkAction,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import rootSlice from "./rootSlice";
import onBoardingSlice from "./OnBoardingSlice";
import homeScreenSlice from "./homeScreenSlice";

const store = configureStore({
  reducer: {
    root: rootSlice.reducer,
    onBoarding: onBoardingSlice.reducer,
    home: homeScreenSlice.reducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
// export type AppDispatch = typeof store.dispatch;
export type AppDispatch = ThunkDispatch<AppState, any, AnyAction>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
export default store;
