import React from 'react';
import unicornImg from '../assets/unicorn.svg';
import CrackerImg from '../assets/Crackers.png'
import { AppPaths } from '../utils/AppPaths';
export const Modal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  return (
    <>
      {/* Overlay */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-full h-full max-w-[422px]">
            {/* Modal content */}
            <div className="relative flex flex-col justify-center h-full bg-white border-2 rounded-lg">
              {/* Close button */}
              <button className="absolute text-black hover:text-gray-700 focus:outline-none right-2 top-2" onClick={onClose}>
                {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg> */}
                <img
                  className="w-8 h-8"
                  alt=""
                  src={require("../assets/close.png")}
                  onClick={onClose}
                />
              </button>

              {/* Image */}
              <div className="flex justify-center py-4 bg-center bg-no-repeat" style={{ backgroundImage: `url(${CrackerImg})`, backgroundRepeat: 'no-repeat', backgroundPosition: '51% 22%', backgroundSize: '76%' }}>
                <img
                  src={unicornImg}
                  alt="Your Image"
                  className="border-2 border-black rounded-lg w-50 h-60"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Background overlay */}
      {isOpen && (
        <div className="fixed inset-0 z-40 bg-black opacity-50" onClick={onClose} aria-hidden="true"></div>
      )}
    </>
  );
};
