import React from "react";
import "./styles.css"; // Import your styling file

const TextInput = ({
  placeholder = "",
  value = "",
  onChange,
  containerStyles = "",
  textStyle = "",
  rows = 1,
  rightIcon = null,
  textInputStyles = {},
  secure = false,
  defaultValue = "",
}: {
  placeholder?: string;
  value?: string;
  onChange?: (e: any) => void;
  containerStyles?: string;
  textStyle?: string;
  rows?: number;
  rightIcon?: any;
  textInputStyles?: React.CSSProperties;
  secure?: boolean;
  defaultValue?: string;
}) => {
  return (
    <div className={`TextInput flex flex-1 flex-row  ${containerStyles} `}>
      {rows === 1 ? (
        <input
          type={secure ? "password" : "text"}
          className={`${
            value && "font-black inter-regular-16"
          } ${textStyle} outline-none`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          style={textInputStyles}
          defaultValue={defaultValue}
        />
      ) : (
        <textarea
          className={`flex flex-1 ${textStyle} outline-none`}
          placeholder={placeholder}
          value={value}
          onChange={
            onChange as (e: React.ChangeEvent<HTMLTextAreaElement>) => void
          }
          style={textInputStyles}
          rows={rows}
        />
      )}
      {rightIcon && rightIcon}
    </div>
  );
};

export default TextInput;
