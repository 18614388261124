import { createSlice } from "@reduxjs/toolkit";

export type HomeScreenState = {
  selectedImoji: null | string;
  recentUsedImoji: Array<Array<string>>;
};

const initialState: HomeScreenState = {
  selectedImoji: null,
  recentUsedImoji: [],
};

const homeScreenSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    reset: () => {
      return { ...initialState };
    },
    setSelctedImoji: (state, action) => {
      state.selectedImoji = action.payload;

      if (
        !state.recentUsedImoji
          ?.map((item) => item[0])
          ?.includes(action.payload[0])
      ) {
        state.recentUsedImoji.push(action.payload);
      }
    },
  },
});

export const { reset, setSelctedImoji } = homeScreenSlice.actions;

export default homeScreenSlice;
