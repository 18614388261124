import moment from "moment";
import Api from "./Api";

export interface Login {
  email: string;
  password: string;
}

export interface ApiResponse {
  status: boolean;
  data: string;
  message: string;
}

const ApiService = {
  async login(data: Login): Promise<ApiResponse> {
    return Api.post<ApiResponse>("/users/login", data);
  },
  async createUser(data: any): Promise<any> {
    return Api.post<ApiResponse>("/users/create", data);
  },
  async getProfile(): Promise<any> {
    return Api.get<ApiResponse>(`/users?date=${moment(new Date()).format('yyyy-MM-DD')}`);
  },
  async getChallenges(): Promise<any> {
    return Api.get<ApiResponse>("/users/challenges")
  },
  async dailyCheckIn(data: any): Promise<any> {
    return Api.post<ApiResponse>("users/daily_checkin", data);
  },
  async editDailyCheckIn(data: any): Promise<any> {
    return Api.put<ApiResponse>("users/daily_checkin", data);
  },
  async getMessages(): Promise<any> {
    return Api.get<ApiResponse>("users/contacts");
  },
  async getMyJourney(): Promise<any> {
    return Api.get<ApiResponse>("users/myjourney");
  },
  async updateUserDetails(data: any): Promise<any> {
    return Api.put<ApiResponse>("users/update", data);
  },
  async updateUserPassword(data: any): Promise<any> {
    return Api.post<ApiResponse>("users/forgot_password", data);
  },
  async getMoodRingData(month: any): Promise<any> {
    return Api.get<ApiResponse>(`users/moodRings/${month}`);
  },
  async answerText(data: any): Promise<any> {
    return Api.post<ApiResponse>("users/challenges/attempt", data);
  },
  async getQuizById(data: any): Promise<any> {
    return Api.get<ApiResponse>(`users/challenge/${data}`);
  },
  async getMessagesById(data: any): Promise<any> {
    return Api.get<ApiResponse>(`users/messages/${data}`);
  },
  async readMessagesById(id: any): Promise<any> {
    return Api.put<ApiResponse>(`users/messages/read/${id}`);
  },
  async sendMessage(data: any): Promise<any> {
    return Api.post<ApiResponse>("users/send/messages" ,data);
  },
  async getFeeds(): Promise<any> {
    return Api.get<ApiResponse>("users/feeds");
  },
  async sendResetEmail(data: any): Promise<any> {
    return Api.get<ApiResponse>(`send-forgot-password-email?email=${data.email}`);
  },
  async checkMessage(): Promise<any> {
    return Api.get<ApiResponse>("users/check_message_status");
  }
};

export default ApiService;
